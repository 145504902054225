import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { POST } from "../../axiosHelper/Helper";
import {
  emailregx,
  failMsg,
  mobileregx,
  successMsg,
} from "../../utilites/Notifcation";
import Inputfields from "../Inputfields";

const LoanForm = ({ loanref, handleLoanForm }) => {
  const initial = {
    name: "",
    mobile: "",
    email: "",
    amount: "",
    description: "",
  };
  const [formData, setformData] = useState(initial);
  const [formError, setformError] = useState(initial);
  const [focus, setfocus] = useState(false);
  const handleChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  const formValidation = (object) => {
    const error = {};

    if (object.name.length === 0) {
      error.name = "Name field is required !";
    }
    if (object.mobile.length === 0) {
      error.mobile = "Mobile field is required !";
    } else if (!mobileregx.test(object.mobile)) {
      error.mobile =
        "Mobile Number should start with 6,7,8,9 and Must be 10 digit !";
    }

    if (object.email.length === 0) {
      error.email = "Email field is required !";
    } else if (!emailregx.test(object.email)) {
      error.email = "This is not a valid Email Format !";
    }

    if (object.amount.length === 0) {
      error.amount = "Amount field is required !";
    }
    if (object.description.length === 0) {
      error.description = "Description field is required !";
    }

    return error;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setfocus(true);
    if (Object.keys(formValidation(formData)).length === 0) {
      addForm();
    }
  };

  const addForm = () => {
    POST("user/loan", formData)
      .then((res) => {
        successMsg(res.statusCodeMessage);
      })
      .catch((err) => {
        failMsg(err);
      })
      .finally(() => {
        setformData(initial);
        setfocus(false);
      });
    console.log(formData);
  };

  useEffect(() => {
    if (focus) {
      setformError(formValidation(formData));
    }
  }, [formData, focus]);
  return (
    <div
      ref={loanref}
      className="fixed formhide w-full h-full lg:overflow-hidden  overflow-auto inset-0 z-10  flex  justify-center lg:items-center p-3 lg:p-10 bg-light-black"
    >
      <div className="lg:w-[70%] w-[100%] h-fit  bg-white rounded-md grid lg:grid-cols-3 grid-cols-1 ">
        <div className=" col-span-2 p-5">
          <div className="flex justify-end ">
            <GrClose
              onClick={handleLoanForm}
              className="cursor-pointer text-2xl"
            />
          </div>
          <h2 className="text-lg font-medium">
            Get rid of your financial burdens with our hassle-free Instant loans
          </h2>
          <form onSubmit={handleSubmit} className="lg:px-10 px-5 py-3">
            <Inputfields
              label={"Name"}
              type={"text"}
              name={"name"}
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              error={formError.name}
              inputstyle={"rounded-md"}
            />

            <Inputfields
              label={"Mobile No"}
              type={"number"}
              name={"mobile"}
              value={formData.mobile}
              onChange={handleChange}
              placeholder="Enter your Mobile No."
              error={formError.mobile}
              inputstyle={"rounded-md"}
            />

            <Inputfields
              label={"Email Id"}
              type={"email"}
              name={"email"}
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your Email Id"
              error={formError.email}
              inputstyle={"rounded-md"}
            />

            <Inputfields
              label={"Loan Amount"}
              type={"number"}
              name={"amount"}
              value={formData.amount}
              onChange={handleChange}
              placeholder="Enter your Loan Amount"
              error={formError.amount}
              inputstyle={"rounded-md"}
            />

            <div className="relative">
              <label htmlFor="description" className="block pb-1 font-medium">
                Any Query
              </label>
              <textarea
                id="description"
                name="description"
                onChange={handleChange}
                value={formData.description}
                placeholder="Any Query..."
                className="text-gray-500 outline-none border border-gray-600 w-full focus:ring-2 focus:border-0 focus:ring-blue-500 rounded-md p-2 mb-2 resize-none"
              ></textarea>
              <p className="text-red-500 font-semibold absolute top-24">
                {formError.description}
              </p>
            </div>
            <button className="w-full bg-custgreen text-white p-2 rounded-md shadow-lg mt-5">
              Submit
            </button>
            <p className="flex flex-col text-center pt-4 gap-2 text-gray-500">
              By proceeding ahead you expressly agree to the CollegeDekho
              <span className="text-[#296aff] cursor-pointer">
                terms of use and privacy policy
              </span>
            </p>
          </form>
        </div>
        <div className=" bg-custgreen lg:rounded-r-md rounded-b-md  p-8 text-white ">
          <h1 className="drop-shadow-4xl text-2xl  font-medium">
            Why CollegeDekho ?
          </h1>
          <ul className="list-disc p-5 space-y-3 font-medium">
            <li>Disbursal of Loan within 72 hours</li>
            <li>Hassle free, 100% Online Process</li>
            <li>Lowest Interest Rate</li>
            <li>No Collateral Required</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LoanForm;
