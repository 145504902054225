import React from "react";
import SwiperSlider from "../../utilites/SwiperSlider";

export default function Featured() {
  return (
    <section className="bg-[#C00404] py-12 text-center">
      <div className="max-w-6xl w-full mx-auto">
        <h3 className="lg:px-16 px-4 mb-6 font-normal lg:text-3xl text-white text-xl pt-12">Watch Featured Colleges</h3>
        <div className="overflow-auto lg:p-0 p-2 h-[500px]">
          <SwiperSlider />
        </div>
      </div>
    </section>
  );
}
