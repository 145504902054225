import React from "react";
import consel from "../../assets/images/consel.webp";
import Allicons from "../../assets/images/Allicons.webp"
import Btn from "../../utilites/Btn";

export default function Counseling() {
  return (
    <section className="py-12  text-center bg-fixed bg-center bg-no-repeat bg-cover h-full" style={{ backgroundImage: `url(${consel})` }}>
      <div className="max-w-6xl w-full mx-auto">
        <h2 className="font-bold lg:text-4xl text-2xl mb-4 text-white">End-to-End <span className="text-[#C00404]">Free Counselling</span> </h2>
        <p className="mb-6 lg:text-3xl text-sm text-white">Looking to get the best career guidance? Our experts know exactly what you need!</p>
        <p className="hidden lg:block text-[#999] mb-12" style={{ textShadow: "0 2px 3px rgb(0 0 0 / 30%)" }}>
          At BookMyCourse, academic experts help you evaluate your career and course choices accurately while taking into account your educational background, strengths & skills. From shortlisting the best colleges to tracking your entire admission process, the counselling by our experts will make
          your higher education journey hassle-free and put you on the path of success.
        </p>
        <div className="flex flex-wrap lg:px-0 px-6 ">
          <div className="lg:w-1/2 w-full">
            <div className="m-auto block relative text-left">
              <div className="text-white lg:text-3xl text-lg mt-2 mb-5">How it works</div>
              <ul className="inline-block mx-3 text-white">
                <li className="tl-item m-auto min-h-[50px] pl-8 pb-2 relative" style={{ borderLeft: "2px solid #555657" }}>
                  <div>Tell us about your College / Course & Location preferences.</div>
                </li>
                <li className="tl-item m-auto min-h-[50px] pl-8 pb-2 relative" style={{ borderLeft: "2px solid #555657" }}>
                  <div>Tell us about your College / Course & Location preferences.</div>
                </li>
                <li className="tl-item m-auto min-h-[50px] pl-8 pb-2 relative" style={{ borderLeft: "2px solid #555657" }}>
                  <div>Tell us about your College / Course & Location preferences.</div>
                </li>
                <li className="tl-item m-auto min-h-[50px] pl-8 pb-2 relative" style={{ borderLeft: "2px solid #555657" }}>
                  <div>Tell us about your College / Course & Location preferences.</div>
                </li>
                <li className="tl-item m-auto min-h-[50px] pl-8 pb-2 relative" style={{ borderLeft: "2px solid #555657" }}>
                  <div>Tell us about your College / Course & Location preferences.</div>
                </li>
                <li className="tl-item m-auto min-h-[50px] pl-8 pb-2 relative" style={{ borderLeft: "0px solid #555657" }}>
                  <div>Tell us about your College / Course & Location preferences.</div>
                </li>
              </ul>
             <Btn text="Talk to our Experts" />
            </div>
          </div>
          <div className="lg:w-1/2 w-full">
            <ul className="flex flex-wrap py-[55px]">
              <li className="p-2 w-1/2 lg:w-1/3">
                <div className="block bg-white rounded shadow-md py-6 text-center text-[#333]  hover:bg-[#C00404] hover:text-white duration-100" style={{borderBottom : '3px solid rgba(58,166,167,1)'}}>
                  <div className="block mx-auto mb-2 w-[50px] h-[50px] bg-no-repeat " style={{background : `url(${Allicons})` , backgroundPosition : '-289px -144px'}}></div>
                  <p>1 on 1 <br></br>  Counselling</p>
                </div>
              </li>
              <li className="p-2 w-1/2 lg:w-1/3">
                <div className="block bg-white rounded shadow-md py-6 text-center text-[#333] hover:bg-[#C00404] hover:text-white duration-100" style={{borderBottom : '3px solid rgba(58,166,167,1)'}}>
                  <div className="block mx-auto mb-2 w-[50px] h-[50px] bg-no-repeat " style={{background : `url(${Allicons})` , backgroundPosition : '-289px -144px'}}></div>
                  <p>1 on 1 <br></br>  Counselling</p>
                </div>
              </li>
              <li className="p-2 w-1/2 lg:w-1/3">
                <div className="block bg-white rounded shadow-md py-6 text-center text-[#333] hover:bg-[#C00404] hover:text-white duration-100" style={{borderBottom : '3px solid rgba(58,166,167,1)'}}>
                  <div className="block mx-auto mb-2 w-[50px] h-[50px] bg-no-repeat " style={{background : `url(${Allicons})` , backgroundPosition : '-289px -144px'}}></div>
                  <p>1 on 1 <br></br>  Counselling</p>
                </div>
              </li>
              <li className="p-2 w-1/2 lg:w-1/3">
                <div className="block bg-white rounded shadow-md py-6 text-center text-[#333] hover:bg-[#C00404] hover:text-white duration-100" style={{borderBottom : '3px solid rgba(58,166,167,1)'}}>
                  <div className="block mx-auto mb-2 w-[50px] h-[50px] bg-no-repeat " style={{background : `url(${Allicons})` , backgroundPosition : '-289px -144px'}}></div>
                  <p>1 on 1 <br></br>  Counselling</p>
                </div>
              </li>
              <li className="p-2 w-1/2 lg:w-1/3">
                <div className="block bg-white rounded shadow-md py-6 text-center text-[#333] hover:bg-[#C00404] hover:text-white duration-100" style={{borderBottom : '3px solid rgba(58,166,167,1)'}}>
                  <div className="block mx-auto mb-2 w-[50px] h-[50px] bg-no-repeat " style={{background : `url(${Allicons})` , backgroundPosition : '-289px -144px'}}></div>
                  <p>1 on 1 <br></br>  Counselling</p>
                </div>
              </li>
              <li className="p-2 w-1/2 lg:w-1/3">
                <div className="block bg-white rounded shadow-md py-6 text-center text-[#333] hover:bg-[#C00404] hover:text-white duration-100" style={{borderBottom : '3px solid rgba(58,166,167,1)'}}>
                  <div className="block mx-auto mb-2 w-[50px] h-[50px] bg-no-repeat " style={{background : `url(${Allicons})` , backgroundPosition : '-289px -144px'}}></div>
                  <p>1 on 1 <br></br>  Counselling</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
   
      </div>
    </section>
  );
}
