import Swal from "sweetalert2";
import { emailregx, mobileregx } from "../../utilites/Notifcation";

export const streamData = [
  "Commerce and Banking",
  "Science (Non-medical)",
  "Science (Medical)",
  "Arts and Humanities",
];
export const boradData = ["ICSE", "CBSE", "State Board", "Others"];
export const universityData = [
  "University One",
  "University Two",
  "University Three",
  "University Four",
  "University Five",
];
export const examData = ["Exam one", "Exam Two", "Exam Three", "Exam Four"];

// FORM VALIDATION FOR THE personalDetails SECTION
export const personalDetailsValidation = (obj) => {
  const error = {};

  if (obj.fullname.length === 0) {
    error.fullname = "First Name field is required !";
  }
  if (obj.guardianname.length === 0) {
    error.guardianname = "MiddleName field is required !";
  }
  if (obj.surname.length === 0) {
    error.surname = "Surname field is required !";
  }

  return error;
};

// FORM VALIDATION FOR THE contactDetailsDetails SECTION
export const contactDetailsValidation = (obj) => {
  const error = {};

  if (obj.mobileno.length === 0) {
    error.mobileno = "Mobile No. field is required !";
  } else if (!mobileregx.test(obj.mobileno)) {
    error.mobileno =
      "Mobile No. should start with 6,7,8,9 and Must be 10 digit !";
  }

  if (obj.email_id.length === 0) {
    error.email_id = "Email Id field is required !";
  } else if (!emailregx.test(obj.email_id)) {
    error.email_id = "This is not a valid Email Format !";
  }

  if (obj.country.length === 0) {
    error.country = "Country field is required !";
  }

  if (obj.city.length === 0) {
    error.city = "City field is required !";
  }

  if (obj.state.length === 0) {
    error.state = "State field is required !";
  }

  if (obj.pincode.length === 0) {
    error.pincode = "Pincode field is required !";
  }

  return error;
};

// FORM VALIDATION FOR THE contactDetailsDetails SECTION
export const photoValidation = (obj) => {
  let error = "";
  if (obj === null) {
    error = "Photo is required !";
  }

  return error;
};

//FUNCITON FOR THE GETING COLLEGEID
export const getArrayOfCollegeid = (obj) => {
  return obj.map((item) => item.id);
};
//FUNCITON FOR THE GETING COLLEGEID
export const getArrayOfprice = (obj) => {
  if (obj.length > 0) {
    const reducer = (accumulator, curr) => accumulator + curr;
    return obj.map((item) => parseFloat(item.price)).reduce(reducer);
  }
  // const arr = [1, 2, 3, 4];
  // console.log(arr);
  // console.log(reducer);
  // console.log(arr.reduce(reducer));
};

//FUNCITON FOR THE GETING  COURSELIST
export const getArrayOfCourseList = (obj) => {
  let courselist = [];
  let newAr = obj.map((item) => {
    return item.courses.map((i) => ({ id: i.id, clg_id: i.college_id }));
  });
  newAr.map((i) => {
    courselist.push(...i);
    return courselist;
  });
  return courselist;
};

//FUNCTION FOR THE LOAD SCRIPT FOR RAZOR PAY
export const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

//FUNCTION FOR THE PAYMENT FAILED
export const paymentStatus = (
  status,
  status_msg,
  mobile_no,
  email_id,
  amount,
  transaction_id
) => {
  Swal.fire({
    icon: status,
    width: "700",
    title: status_msg,
    text: `<pre>Mobile No.:${mobile_no}      Email id:${email_id}</br>Amount Paid:${amount}      Transaction ID:${transaction_id}</pre>`,
    showConfirmButton: true,
    // timer: 1500
  });
};
