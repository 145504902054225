import "./styles/App.css";
import Mainlayout from "./components/Mainlayout";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import CollegeList from "./pages/collegeList/CollegeList";
import Applicationform from "./pages/ApplicationForm/Applicationform";
import ApplicationHome from "./pages/ApplicationHome";
import CollegeDetails from "./pages/CollegeDetails";
import Overview from "./components/collegeDetails/Overview";
import Courses from "./components/collegeDetails/Courses";
import Campus from "./components/collegeDetails/Campus";
import Placement from "./components/collegeDetails/Placement";
import NewsDetail from "./components/collegeDetails/NewsDetail";
import Emptycart from "./components/Emptycart";
import PageNotFound from "./components/PageNotFound";
import { useState } from "react";
import Gallery from "./components/collegeDetails/Gallery";
import Profile from "./components/Login/Profile";
import AgentSignUp from "./pages/Agent/AgentSignUp";

function App() {
  // const data=[
  //   {
  //     navitem:'College',
  //     subnavitem:['Engginer','science'],
  //     subitem:['viva','vartak']

  //   }
  // ]

  const [filterClg, setfilterClg] = useState([]);

  return (
    <>
      <Routes>
        <Route path="/*" element={<PageNotFound />} />
        <Route path="/agentsignup" element={<AgentSignUp />} />

        <Route element={<Mainlayout />}>
          <Route path="/" element={<Home setfilterClg={setfilterClg} />} />
          <Route
            path="/agent/:agentCode"
            element={<Home setfilterClg={setfilterClg} />}
          />
          <Route
            path="/collegelist/:id"
            element={<CollegeList filterClg={filterClg} />}
          />
          <Route path="/collegedetail/:clgname" element={<CollegeDetails />}>
            <Route index path="overview/:id" element={<Overview />} />
            <Route path="courses/:id" element={<Courses />} />
            <Route path="campus/:id" element={<Campus />} />
            <Route path="placement/:id" element={<Placement />} />
            <Route path="newdetail/:id" element={<NewsDetail />} />
            <Route path="gallery/:id" element={<Gallery />} />
          </Route>
          <Route path="/application" element={<Applicationform />} />
          {/* <Route path="/application" element={<Applicationform />} /> */}
          <Route path="/apphome" element={<ApplicationHome />} />
          <Route path="/emptycart" element={<Emptycart />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
