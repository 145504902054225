import React from "react";
import { BsFillCartFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";

const CartBtn = () => {
  const { totalUniqueItems } = useCart();
  

  const navigate = useNavigate();
  return (
    <div
      className=" relative cursor-pointer"
      onClick={() => {
        totalUniqueItems === 0
          ? navigate("/emptycart")
          : navigate(`/application`);
      }}
    >
      <BsFillCartFill className="text-2xl text-red-500" />
      <div className="absolute -top-2 -right-4 w-6 h-6 rounded-full  bg-red-600 text-white font-medium text-sm flex justify-center items-center ">
        {totalUniqueItems}
      </div>
    </div>
  );
};

export default CartBtn;
