import React from 'react'
import TestimonialsSlider from '../../utilites/TestimonialsSlider'

export default function Testimonials() {
  return (
    <section className='bg-[#f7f7f7] py-12 text-center'>
        <div className='max-w-6xl w-full mx-auto p-2 lg:p-0'>
            <h3 className='px-16 mb-6 font-normal text-3xl text-[#c00404]'>Testimonials</h3>
            <p className='text-[#999] mb-12 text-sm'>What are people saying about us!</p>
            <div >
                <TestimonialsSlider />
            </div>
        </div>
    </section>
  )
}
