import React, { useState } from "react";
import Button from "../Button";
import Inputfields from "../Inputfields";
import Dropdown from "../Dropdown";
import Logo from "../../assets/images/logo.png";
import usePost from "../../CustomHook/usePost";
import useGet from "../../CustomHook/useGet";

const Register = ({ handleCurrentForm }) => {
  const initialForm = {
    name: "",
    email: "",
    mobile: "",
    stream: "",
    state: "",
    level: "",
    boards: "",
    password: "",
  };
  const [formData, setformData] = useState(initialForm);
  const handleChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const { createData, data } = usePost(`user/student`);

  const { data: streamData } = useGet(`user/stream`);
  const { data: stateData } = useGet(`user/state`);

  const handleSubmit = (e) => {
    e.preventDefault();
    createData(formData, () => {});
    handleCurrentForm(0);
  };

  return (
    <>
      <img className="w-28 h-12" src={Logo} alt="" />
      <h2 className="font-bold text-xl">Welcome Back !</h2>
      <p>Sign in to Continue</p>
      <form onSubmit={handleSubmit} className="w-full">
        <div className=" w-full px-2 h-96 overflow-auto">
          <Inputfields
            label={"Name"}
            type={"text"}
            name={"name"}
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your Name"
            // error={formError.name}
            inputstyle={"rounded-md"}
            required={true}
          />
          <Inputfields
            label={"Email"}
            type={"email"}
            name={"email"}
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your Email"
            // error={formError.name}
            inputstyle={"rounded-md"}
            required={true}
          />
          <Inputfields
            label={"Mobile no."}
            type={"number"}
            name={"mobile"}
            value={formData.mobile}
            onChange={handleChange}
            placeholder="Enter your Mobile no."
            // error={formError.name}
            inputstyle={"rounded-md"}
            required={true}
          />

          <Dropdown
            label={" Preferred Stream"}
            name={"stream"}
            value={formData.stream}
            onChange={handleChange}
            error={""}
            required={true}
          >
            <option>--Select Stream--</option>
            {streamData?.serverData.map((item, i) => (
              <option key={i} value={item.id}>
                {item.name}
              </option>
            ))}
          </Dropdown>

          <Dropdown
            label={" Current State"}
            name={"state"}
            value={formData.state}
            onChange={handleChange}
            error={""}
            required={true}
          >
            <option>--Select State--</option>
            {stateData?.serverData.map((item, i) => (
              <option key={i} value={item.id}>
                {item.state_name}
              </option>
            ))}
          </Dropdown>

          <Dropdown
            label={" Preferred Level"}
            name={"level"}
            value={formData.level}
            onChange={handleChange}
            error={""}
            required={true}
          >
            <option>--Select Level--</option>
            <option value={1}>UG</option>
            <option value={2}>PG</option>
            <option value={3}>Diploma</option>
            <option value={4}>Ph.D</option>
            <option value={5}>Certificate</option>
          </Dropdown>

          <Dropdown
            label={" Board"}
            name={"boards"}
            value={formData.boards}
            onChange={handleChange}
            error={""}
            required={true}
          >
            <option>--Select Board--</option>
            <option value={1}>ICSE/CBSE/IB</option>
            <option value={2}>State Board</option>
            <option value={3}>Others</option>
          </Dropdown>

          <Inputfields
            label={"Password"}
            type={"text"}
            name={"password"}
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter your Password"
            // error={formError.name}
            inputstyle={"rounded-md"}
            required={true}
          />
        </div>

        <Button label="Submit" />

        <p className="text-center mt-5">
          Already have Account ?
          <span
            onClick={() => handleCurrentForm(0)}
            className="text-blue-500 cursor-pointer hover:underline"
          >
            Login
          </span>
        </p>
      </form>
    </>
  );
};

export default Register;
