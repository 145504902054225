import React, { useEffect } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import useAuth from "../../CustomHook/useAuth";
import CartBtn from "./common/CartBtn";
import ContactHeader from "./common/ContactHeader";
import LoginBtn from "./common/LoginBtn";
import Logo from "./common/Logo";
import SearchBtn from "./common/SearchBtn";

const DesktopNavbar = ({ navItem }) => {
  const { userName, logOut } = useAuth();

  return (
    <>
      {/* TOP HEADER */}
      <ContactHeader containerStyle={" px-32"} />

      {/* MAIN NAVBAR  */}
      <div className=" h-16 px-20 flex justify-between items-center">
        {/* LOGO SECTION */}
        <Logo />

        {/* NAVIGATION SECTION */}
        <div className="flex items-center gap-3">
          {/* MAPPING NAVIGATION SECTION */}
          <ul className="capitalize flex items-center gap-3">
            {navItem?.map((item, i) => (
              <li key={i} className="flex  items-center gap-1 cursor-pointer">
                <span className="flex gap-3 items-center">{item.title}</span>
                <AiOutlineRight className="lg:rotate-90" />
              </li>
            ))}
          </ul>

          {/* ICON SECTION OF NAVBAR */}
          <div className="flex items-center relative gap-3 ">
            <div className="login_btn">
              <LoginBtn />
              {userName !== null && (
                <div className="logoutdiv absolute top-10 w-40 min-h-[4rem] font-medium text-black text-center px-2 rounded-md bg-white shadow-xl">
                  <Link
                    to="/profile"
                    className="hover:text-blue-800 hover:underline"
                  >
                    My Profile
                  </Link>

                  <button onClick={logOut}>logouts</button>
                </div>
              )}
            </div>
            <SearchBtn />

            {/* CART SECTION */}
            <CartBtn />
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopNavbar;
