import React from "react";
import question from "../../assets/images/question.webp";
import profileImg from "../../assets/images/Allicons.webp";


export default function Questions() {
  return (
    <section className="relative text-left h-full py-16 text-white " style={{ backgroundImage: "linear-gradient(90deg,#3aa6a7,#348fd6)" }}>
      <img className="hidden lg:block absolute right-0 top-0 left-auto max-w-full" src={question} alt='' />
      <div className="max-w-6xl w-full mx-auto p-2 lg:p-0">
        <div className="flex flex-wrap ">
          <div className="py-8  px-4 lg:px-20 w-full float-left relative ">
            <h3 className="text-xl lg:text-3xl font-normal p-0 mb-0 " style={{ textShadow: "0 2px 3px rgb(0 0 0 / 30%)" }}>
              Have a Question ?
            </h3>
            <h2 className="italic font-bold text-3xl lg:text-5xl mt-5 text-white" style={{ textShadow: "0 2px 3px rgb(0 0 0 / 30%)" }}>
              {" "}
              We are here to help!
            </h2>
            <p className="text-white mt-4 mb-8 " style={{ textShadow: "0 2px 3px rgb(0 0 0 / 30%)" }}>
              If you have any questions for us, feel free to reach out to us on the details given below.
            </p>
            <div className="w-[325px] lg:w-[370px] mb-5 bg-white rounded-lg shadow-lg p-5">
              <div className="float-left w-[60px] h-[40px] bg-no-repeat " style={{ backgroundImage: `url(${profileImg})`, backgroundPosition: "-170px -50px" }}></div>
              <div className="overflow-hidden pl-5 ">
                <p className="text-[#999] m-0 text-sm ">For admissions mail us on</p>
                <div className="text-lg font-bold text-[#3aa6a7] ">hello@bookmycourse.net</div>
              </div>
              <div className="overflow-hidden  pl-5"></div>
            </div>
            <div className="w-[325px] lg:w-[370px] mb-5 bg-white rounded-lg shadow-lg p-5">
              <div className="float-left w-[60px] h-[40px] bg-no-repeat " style={{ backgroundImage: `url(${profileImg})`, backgroundPosition: "-170px -50px" }}></div>
              <div className="overflow-hidden pl-5 ">
                <p className="text-[#999] m-0 text-sm ">For admissions mail us on</p>
                <div className="text-lg font-bold text-[#3aa6a7] ">hello@bookmycourse.net</div>
              </div>
              <div className="overflow-hidden  pl-5"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
