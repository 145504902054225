import React from "react";
import { BiSearch } from "react-icons/bi";

const SearchBtn = () => {
  return (
    <div className="search border border-emerald-500 p-1 lg:p-1 rounded-md cursor-pointer">
      <BiSearch className="w-7 h-7 text-[#54ACAC]" />
    </div>
  );
};

export default SearchBtn;
