import React from 'react'
import { Link } from 'react-router-dom'
import { BiPhoneCall } from 'react-icons/bi';
import { GrMail } from 'react-icons/gr';
import { AiFillTwitterCircle,AiOutlineCopyright } from 'react-icons/ai';
import { BsFacebook, BsLinkedin, BsYoutube } from 'react-icons/bs';
import { FaInstagramSquare } from 'react-icons/fa';
import logo from '../assets/images/logo.png'

const Footer = () => {
    return (
        <div className='bg-[#333333] pb-5'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-0 py-5 px-5 lg:px-20'>
                <div>
                    <h1 className='text-white font-bold'>Trending Links</h1>
                    <div className='text-gray-400'>
                        <Link to={'/home'}>JEE MAINS QUESTIONS PAPERS</Link>
                    </div>
                </div>
                <div className='text-gray-400'>
                    <h1 className='text-white font-bold'>Trending Links</h1>
                    <div>
                        <Link to={'/home'}>JEE MAINS QUESTIONS PAPERS</Link>
                    </div>
                </div>
                <div className='text-gray-400'>
                    <h1 className='text-white font-bold'>Trending Links</h1>
                    <div>
                        <Link to={'/home'}>JEE MAINS QUESTIONS PAPERS</Link>
                    </div>
                </div>
                <div className=' space-y-8'>
                    <img class="w-[120px] h-[40px] bg-no-repeat mb-1" src={logo} alt="" />
                    <div className='text-white space-y-3'>
                        <p className='text-gray-400'>Near Mumbai-400006 Maharashtra</p>
                        <span className='flex items-center gap-2 '><BiPhoneCall className='text-[#54ACAC]' /> <p className='text-[0.8rem]'>1800-572-9877</p></span>
                        <span className='flex items-center gap-2 '><GrMail className='text-[#54ACAC]' /> <p className='text-[0.8rem]'>bookmycourse@gmail.net</p></span>
                    </div>

                </div>
            </div>
            <div className='flex  items-center justify-center  gap-2 py-3 border-t border-b border-gray-600'>
                <p className='text-white font-semibold text-base'>Connect With us:</p>
                <BsFacebook className='text-blue-600 w-6 h-6 bg-white rounded-full' /> <BsLinkedin className='text-blue-800 rounded-full w-6 h-6 bg-white' /> <AiFillTwitterCircle className='w-7 h-7 text-blue-400 bg-white rounded-full p-0' /> <BsYoutube className='bg-red-500 text-white w-7 h-7 rounded-full p-1' /> <FaInstagramSquare className='h-6 w-6  text-[#D13871]' />
            </div>
            <div className='flex justify-evenly flex-wrap w-full'>
                <div className='flex items-center flex-wrap gap-4 justify-center text-gray-400 '>
                    <Link to={'/home'}>About Us</Link>
                    <Link to={'/home'}>Contact Us</Link>
                    <Link to={'/home'}>Join Us</Link>
                    <Link to={'/home'}>Terms & Conditions</Link>
                    <Link to={'/home'}>Privacy Policy</Link>
                </div>
                <p className='text-white text-lg flex items-center gap-2 mt-5'><AiOutlineCopyright className='text-xl'/> 2022 bookmycourse.net</p>
            </div>
        </div>
    )
}

export default Footer