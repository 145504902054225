import React from 'react'
import { useNavigate } from 'react-router-dom';
// import { removeWhitespace } from '../../../utilites/Notifcation';

const TopCollege = ({ topclg }) => {
    let navigate = useNavigate();
    return (<>
        {topclg?.map((item, i) => (
            <>
                <div onClick={() => {
                    // navigate(`/collegedetail/${removeWhitespace(item.names)}/courses/${item.id}`);
                }} key={i} className='hover:text-blue-600' >
                    {item.names}
                </div>

            </>
        ))}
    </>
    )
}

export default TopCollege