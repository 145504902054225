import axios from "axios";
import { useState } from "react";
import { failMsg, successMsg, warningMsg } from "../utilites/Notifcation";
import { BaseUrl, header } from "./file";

const usePost = (url) => {
  const [data, setdata] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const createData = (obj, cb = () => { }) => {
    setisLoading(true);
    axios
      .post(`${BaseUrl}${url}`, obj, header)
      .then((res) => {
        setdata(res.data);

        if (res.data.error.length > 0) {
          warningMsg(res.data.error);
        } else {
          successMsg(res.data.statusCodeMessage);
          cb();
        }
      })
      .catch((err) => {
        failMsg(err);
        console.log("err", err);
      })
      .finally(() => setisLoading(false));
  };

  return { data, isLoading, createData };
};

export default usePost;
