import React, { useRef } from "react";
import ReactDOM from "react-dom";
import "./modal.css";
import { AiOutlineClose } from "react-icons/ai";

const Modal = ({ isOpen, setisOpen, children }) => {
  const modalContainer = useRef();
  const modal = useRef();

  const closeModal = () => {
    modalContainer.current.classList.add("fadeOut");
    modal.current.classList.add("slideOut");

    modalContainer.current.addEventListener(
      "animationend",
      () => {
        modalContainer.current.classList.add("fadeOut");
        modal.current.classList.add("slideOut");
        setisOpen(false);
      },
      { once: true }
    );
  };

  return ReactDOM.createPortal(
    <>
      {isOpen && (
        <div
          ref={modalContainer}
          // onClick={closeModal}
          className={`modal_container  ${isOpen && "fadeIn"}`}
        >
          <div
            ref={modal}
            onClick={(e) => e.stopPropagation()}
            className={`modal w-[95%] lg:w-[65%] ${isOpen && "slideIn"}`}
          >
            <AiOutlineClose
              onClick={closeModal}
              className="self-end text-4xl cursor-pointer m-2 hover:bg-slate-500 hover:rounded-full hover:text-white p-2 "
            />

            {children}
          </div>
        </div>
      )}
    </>,
    document.getElementById("portal")
  );
};

export default Modal;
