const { REACT_APP_BASEURL } = process.env;
export const BaseUrl = REACT_APP_BASEURL;
export const ImgBaseUrl =
  "http://wpsolutions.in/collegeportal/api/storage/app/images/college/gallery/";

sessionStorage.setItem(
  "loginStatus",
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjEsImF1ZCI6ImNvbGxlZ2Vwb3J0YWwuY29tIiwiaWF0IjoxMzU2OTk5NTI0LCJuYmYiOjEzNTcwMDAwMDB9.gY-cODmn6iRaZG3zsVK9EDm_ijsTcrjrE8DcNuu8vy0"
);

export const header = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem("loginStatus")}`,
  },
};
