import React, { useState } from "react";
import { Link } from 'react-router-dom'
import heroBg from "../../assets/images/herobg.webp";
import icons from "../../assets/images/Allicons.webp";
import { IoCloseSharp } from "react-icons/io5";
import { GET } from "../../axiosHelper/Helper";
import { debounce, failMsg, removeWhitespace } from "../../utilites/Notifcation";
import NoData from "../NoData";


export default function HeroSection() {
  const [searchData, setsearchData] = useState(null);
  const [focus, setfocus] = useState(false);
  const [input, setinput] = useState('')
  const handleChange = (e) => {
    setinput(e.target.value)
  }
  const loadSearch = () => {
    GET(`/user/college/popular`)
      .then(res => {
        setsearchData(res.serverData);
      })
      .catch(err => {
        failMsg(err)
      })
  }


  return (
    <div>
      <div>
        <img src={heroBg} className="absolute w-full lg:min-h-[500px] min-h-[400px] lg:h-[500px] h-[400px]  -z-10" alt="" />
        <div className="c">
          <div className="relative text-center lg:pt-[150px] pt-[60px]">
            <h1 className="text-white mt-[50px] leading-10 text-3xl lg:text-5xl font-bold" style={{ textShadow: "0 6px 5px rgb(0 0 0 / 25%)" }}>
              <span className="text-[#C00404]">Search Colleges,</span>   Courses and Apply !
            </h1>
          </div>
          <div className="relative lg:w-[53%] w-full mt-9 lg:mx-auto lg:mb-14 mb-4 px-2 " >
            <input onFocus={() => { setfocus(true) }} onKeyUp={() => { debounce(loadSearch()) }} onChange={handleChange} value={input} type="search" placeholder="What are you looking for ?" className={`${focus ? 'rounded-b-none' : 'rounded-2xl'} w-full border-1 focus:outline-none border-white h-10 px-10 py-6 rounded-2xl   transition-all`} />

            <i className="absolute left-[10px] text-gray-600 top-[18px] text-xl h-4 w-4 p-3" style={{ background: `url(${icons}) no-repeat -235px -10px` }}></i>

            <IoCloseSharp onClick={() => { setfocus(false); setinput(''); setsearchData(null) }} className={`hover:bg-slate-500 hover:text-white ${focus ? 'block' : 'hidden'} cursor-pointer  rounded-full absolute right-5 top-2 text-3xl`} />
            {/* SEARCH DATA SECTION */}
            <div className={`bg-white  no-scrollbar  ${focus ? 'block rounded-b-2xl py-3 ' : 'hidden'}  font-medium max-h-60 shadow-lg overflow-auto `}>
              {searchData === null && <p className={` ${focus ? 'block' : 'hidden'} px-10`}>Enter College name to Search...</p >}
              {searchData?.map((item, i) => (
                <Link to={`/collegedetail/${removeWhitespace(item.names)}/overview/${item.id}`} key={i} className=" px-10 py-2 block cursor-pointer hover:bg-gray-300">{item.names}</Link>

              ))}
              {searchData === null && <NoData />}

            </div>
          </div>
        </div>
      </div>
      <div className="scroll-smooth " style={{ background: 'linear-gradient(180deg, rgba(0,0,0,0) 39%, #ffffff 132%)' }}>
        <div className="max-w-6xl w-full mx-auto p-2 lg:p-0">
          <div className="rounded-lg mb-2 w-full py-2 px-3 text-[#C00404] bg-white border-1 border-[#e8e8e8]">
            <ul className="flex flex-wrap py-1">
              <div className="lg:w-1/6  w-1/3">
                <div className="lg:p-4 py-2 px-0 text-center">
                  <div className="mx-auto w-[100px] h-[100px] inline-block mb-1 overflow-hidden bg-no-repeat" style={{ backgroundImage: `url(${icons})`, backgroundPosition: "-360px -22px" }}></div>
                  <p className="font-semibold text-center mt-4">Explore Colleges, Exams & More</p>
                </div>
              </div>
              <div className="lg:w-1/6  w-1/3">
                <div className="lg:p-4 py-2 px-0 text-center">
                  <div className="mx-auto w-[100px] h-[100px] inline-block mb-1 overflow-hidden bg-no-repeat" style={{ backgroundImage: `url(${icons})`, backgroundPosition: "-488px -22px" }}></div>
                  <p className="font-semibold text-center mt-4">Common Application Form</p>
                </div>
              </div>
              <div className="lg:w-1/6  w-1/3">

                <div className="lg:p-4 py-2 px-0 text-center">
                  <div className="mx-auto w-[100px] h-[100px] inline-block mb-1 overflow-hidden bg-no-repeat" style={{ backgroundImage: `url(${icons})`, backgroundPosition: "-616px -22px" }}></div>
                  <p className="font-semibold text-center mt-4">Free Counselling By Experts</p>
                </div>
              </div>
              <div className="lg:w-1/6  w-1/3">
                <div className="lg:p-4 py-2 px-0 text-center">
                  <div className="mx-auto w-[100px] h-[100px] inline-block mb-1 overflow-hidden bg-no-repeat" style={{ backgroundImage: `url(${icons})`, backgroundPosition: "-742px -22px" }}></div>
                  <p className="font-semibold text-center mt-4">Study Abroad</p>
                </div>
              </div>
              <div className="lg:w-1/6  w-1/3">
                <div className="lg:p-4 py-2 px-0 text-center">
                  <div className="mx-auto w-[100px] h-[100px] inline-block mb-1 overflow-hidden bg-no-repeat" style={{ backgroundImage: `url(${icons})`, backgroundPosition: "-870px -22px" }}></div>
                  <p className="font-semibold text-center mt-4">Insurance</p>
                </div>
              </div>
              <div className="lg:w-1/6  w-1/3">
                <div className="lg:p-4 py-2 px-0 text-center">
                  <div className="mx-auto w-[100px] h-[100px] inline-block mb-1 overflow-hidden bg-no-repeat" style={{ backgroundImage: `url(${icons})`, backgroundPosition: "-998px -22px" }}></div>
                  <p className="font-semibold text-center mt-4">Avail Education Loan</p>
                </div>
              </div>

            </ul>
          </div>
          <div className="w-full ">
            <Link to="/home" className="maruqeeTag mt-2 py-1 relative bg-[#f7dd7d] text-[#333] inline-block h-7 leading-6  text-xs font-bold float-left ">
              <span>Trending today</span>
            </Link>
            <div className=" w-[60%] lg:w-[86%] mx-auto whitespace-nowrap overflow-hidden relative">
              <div className="marquee_content">
                <ul className=" movinganimation  flex justify-around list-none p-0 m-0">
                  <li className="py-2">
                    <Link to="/home" className="text-[#C00404]">
                      CSIR NET 2022 Question Paper
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link to="/home" className="text-[#C00404]">
                      CSIR NET 2022 Question Paper
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link to="/home" className="text-[#C00404]">
                      CSIR NET 2022 Question Paper
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link to="/home" className="text-[#C00404]">
                      CSIR NET 2022 Question Paper
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link to="/home" className="text-[#C00404]">
                      CSIR NET 2022 Question Paper
                    </Link>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
