import React, { useEffect, useRef } from 'react'
import HeroSection from '../../components/Homepage/HeroSection'
import Finder from '../../components/Homepage/Finder'
import Featured from '../../components/Homepage/Featured'
import Counseling from '../../components/Homepage/Counseling'
import Application from '../../components/Homepage/Application'
import Loan from '../../components/Homepage/Loan'
import Popular from '../../components/Homepage/Popular'
import Trending from '../../components/Homepage/Trending'
import Testimonials from '../../components/Homepage/Testimonials'
import Media from '../../components/Homepage/Media'
import Questions from '../../components/Homepage/Questions'
import Aboutus from '../../components/Homepage/Aboutus'
import { useOutletContext, useParams } from "react-router-dom";
import LoanForm from '../../components/Homepage/LoanForm'

const Home = ({ setfilterClg }) => {
  const [setbgColor] = useOutletContext();
  const loanForm = useRef();
  const { agentCode } = useParams();
  const scrollevent = () => {
    let scrollHeight = window.scrollY;

    if (scrollHeight >= 10) {
      setbgColor(['bg-white sticky top-0 shadow-xl', 'bg-black'])

    }
    else {
      setbgColor(['bg-transparent absolute lg:text-white', 'bg-white'])
    }
  }

  const handleLoanForm = () => {
    loanForm.current.classList.toggle('formhide');
  }

  useEffect(() => {
    sessionStorage.setItem('agentCode', agentCode || "null")
  }, [agentCode])


  useEffect(() => {
    window.addEventListener('scroll', scrollevent)
    return () => {
      window.removeEventListener('scroll', scrollevent)
    }
    // eslint-disable-next-line
  }, [])

  const data = {
    college_name: 'Viva College name',
    contact_no: '8763789736',
    email_id: 'bookmycourse@gmail.com',
    website: 'bookmycourse.com',
    address: 'near mumbai',
    latitude: 19.4552373,
    longitude: 72.7946657,
  }
  return (
    <div>
      <HeroSection />
      <Finder setfilterClg={setfilterClg} />
      <Featured />
      <Counseling />
      <Application />
      <Loan onClick={handleLoanForm} />
      <Popular />
      <Trending />
      <Testimonials />
      <Media />
      <Questions />
      <Aboutus data={data} height={'500'} width={'800'} />
      <LoanForm loanref={loanForm} handleLoanForm={handleLoanForm} />

    </div>
  )
}

export default Home