import React from 'react'
import loan from '../../assets/images/loan.webp'
import icons from "../../assets/images/Allicons.webp";
import Btn from '../../utilites/Btn';


export default function Loan({onClick}) {
  return (
    <section className='py-12 text-center bg-fixed bg-center bg-cover h-full' style={{backgroundImage : `url(${loan})`}} >
        <div className="max-w-6xl w-full mx-auto p-2 lg:p-0">
            <h2 className='lg:px-16 px-0 font-bold lg:text-4xl text-2xl mb-3 lg:mb-8 text-[#c00404]' style={{textShadow : '0 2px 3px rgb(0 0 0 / 30%)'}}>Easiest Education Loan</h2>
            <h3 className='lg:px-16 mb-8 text-white text-sm lg:text-3xl' style={{textShadow : '0 2px 3px rgb(0 0 0 / 30%)'}}>Instant Loan on <span className='text-[#c00404]'>Lowest Interest Rates</span> </h3>
            <p className='lg:block hidden text-white text-sm px-16 mb-11' style={{textShadow : '0 2px 3px rgb(0 0 0 / 30%)'}}>Don’t let the financial stress affect your chances of studying in the college of your dreams. Turn to BookMyCourse Loan Facility - a simple, hassle-free solution that will take away your burden of paying the entire college fees at once!</p>
            <ul className='flex flex-wrap lg:px-5 px-0 mb-12'>
                <li className='lg:w-1/6 w-1/2 py-4 px-5'>
                    <div className="block rounded py-4 text-center ">
                        <div className="rounded-full w-[90px] h-[90px] bg-white inline-block mb-1 overflow-hidden">
                            <span className='block my-5 mx-auto w-[50px] h-[50px] bg-no-repeat' style={{background : `url(${icons})` , backgroundPosition  : "-20px -306px"}}></span>
                        </div>
                            <p className='font-semibold text-white' >Disbursal within 72 Hours</p>
                    </div>
                </li>
                <li className='lg:w-1/6 w-1/2 py-4 px-5'>
                    <div className="block rounded py-4 text-center ">
                        <div className="rounded-full w-[90px] h-[90px] bg-white inline-block mb-1 overflow-hidden">
                            <span className='block my-5 mx-auto w-[50px] h-[50px] bg-no-repeat' style={{background : `url(${icons})` , backgroundPosition  : "-20px -306px"}}></span>
                        </div>
                            <p className='font-semibold text-white' >Disbursal within 72 Hours</p>
                    </div>
                </li>
                <li className='lg:w-1/6 w-1/2 py-4 px-5'>
                    <div className="block rounded py-4 text-center ">
                        <div className="rounded-full w-[90px] h-[90px] bg-white inline-block mb-1 overflow-hidden">
                            <span className='block my-5 mx-auto w-[50px] h-[50px] bg-no-repeat' style={{background : `url(${icons})` , backgroundPosition  : "-20px -306px"}}></span>
                        </div>
                            <p className='font-semibold text-white' >Disbursal within 72 Hours</p>
                    </div>
                </li>
                <li className='lg:w-1/6 w-1/2 py-4 px-5'>
                    <div className="block rounded py-4 text-center ">
                        <div className="rounded-full w-[90px] h-[90px] bg-white inline-block mb-1 overflow-hidden">
                            <span className='block my-5 mx-auto w-[50px] h-[50px] bg-no-repeat' style={{background : `url(${icons})` , backgroundPosition  : "-20px -306px"}}></span>
                        </div>
                            <p className='font-semibold text-white' >Disbursal within 72 Hours</p>
                    </div>
                </li>
                <li className='lg:w-1/6 w-1/2 py-4 px-5'>
                    <div className="block rounded py-4 text-center ">
                        <div className="rounded-full w-[90px] h-[90px] bg-white inline-block mb-1 overflow-hidden">
                            <span className='block my-5 mx-auto w-[50px] h-[50px] bg-no-repeat' style={{background : `url(${icons})` , backgroundPosition  : "-20px -306px"}}></span>
                        </div>
                            <p className='font-semibold text-white' >Disbursal within 72 Hours</p>
                    </div>
                </li>
                <li className='lg:w-1/6 w-1/2 py-4 px-5'>
                    <div className="block rounded py-4 text-center ">
                        <div className="rounded-full w-[90px] h-[90px] bg-white inline-block mb-1 overflow-hidden">
                            <span className='block my-5 mx-auto w-[50px] h-[50px] bg-no-repeat' style={{background : `url(${icons})` , backgroundPosition  : "-20px -306px"}}></span>
                        </div>
                            <p className='font-semibold text-white' >Disbursal within 72 Hours</p>
                    </div>
                </li>
               
            </ul>
            <div>
                <Btn onClick={onClick} text="Apply Now" />
            </div>
        </div>
    </section>
  )
}
