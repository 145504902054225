import React, { Component } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export default class SwiperSlider extends Component {
  render() {
    return (
      <div>
        <Swiper
          modules={[Pagination, Navigation]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={true}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          <SwiperSlide>
            <iframe
              className="mx-auto mb-10 aspect-auto w-full"
              height="400"
              src="https://www.youtube.com/embed/I5OXEvF0liI"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </SwiperSlide>
          <SwiperSlide>
            <iframe
              className="mx-auto mb-10 w-full"
              height="400"
              src="https://www.youtube.com/embed/RlOB3UALvrQ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </SwiperSlide>
          <SwiperSlide>
            <iframe
              className="mx-auto mb-10 w-full"
              height="400"
              src="https://www.youtube.com/embed/5Eqb_-j3FDA"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </SwiperSlide>
          <SwiperSlide>
            <iframe
            className="mx-auto mb-10 w-full"
            height="400"
              src="https://www.youtube.com/embed/X0tOpBuYasI"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }
}
