import React from 'react'
import Loan from './common/Loan';
import Image from './common/Image'
import NewsSlider from './common/NewsSlider';
import { useOutletContext } from 'react-router-dom';
import Facilities from './common/Facilities';
import NoData from '../NoData';

const Campus = () => {
    const [{ all_news, college_gallery, campus_data }] = useOutletContext();
    return (
        <div className='grid grid-cols-1 lg:grid-cols-4 '>
            <div className='col-span-3 space-y-5'>
                {/* SECTION FOR THE  DESCRIPTION */}
                <div className='bg-white shadow-xl rounded-md p-3'>
                    <h1 className='font-semibold'>Campus Description</h1>
                    {campus_data !== null ?
                        <div className=' overflow-auto' dangerouslySetInnerHTML={{ __html: campus_data.description }} />
                        : <NoData />}



                </div>

                {/* SECTION FOR THE  FACILITIES */}
                <Facilities campus_data={campus_data} />

                {/* SECTION FOR THE EDUCATION LOAN */}
                <Loan />

                {/* SECTION FOR THE IMAGE GALLERY OVERVIEW */}
                {college_gallery[0] !== null ?
                    <Image data={college_gallery[0]} />
                    : <NoData />}


                {/* SECTION FOR THE  RELATED NEWS */}
                {all_news !== null ?
                    <NewsSlider all_news={all_news} />
                    : <NoData />}

            </div>


        </div>
    )
}

export default Campus