import React, { useEffect, useRef, useState } from "react";
import Dropdown from "../../components/Dropdown";
import Inputfields from "../../components/Inputfields";
import { AiOutlinePlus } from "react-icons/ai";
import img from "../../assets/images/Telangana_MBBS_Admission.jpg";
import user from "../../assets/images/user.png";
import { useOutletContext, useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import RadioFields from "../../components/RadioFields";
import {
  addToCartButtonState,
  debounce,
  failMsg,
  getArrayOfYears,
  successMsg,
  warningMsg,
} from "../../utilites/Notifcation";
import axios from "axios";
import {
  boradData,
  contactDetailsValidation,
  examData,
  getArrayOfCollegeid,
  getArrayOfCourseList,
  getArrayOfprice,
  loadScript,
  paymentStatus,
  personalDetailsValidation,
  photoValidation,
  streamData,
  universityData,
} from "./Data";
import Swal from "sweetalert2";
import { POST } from "../../axiosHelper/Helper";
import useRazorpay from "react-razorpay";

const Applicationform = () => {
  const { REACT_APP_BASEURL } = process.env;
  const [setbgColor] = useOutletContext();
  const navigate = useNavigate();
  const { items, removeItem, totalUniqueItems } = useCart();
  const pincoderef = useRef();

  const agentCode = sessionStorage.getItem("agentCode");

  const Razorpay = useRazorpay();

  const [focus, setfocus] = useState(false);

  // STATE DECLARATION FOR THE CART COURSE LIST SECTION
  const [checkcourselist, setcheckcourselist] = useState([]);

  // STATE DECLARATION FOR THE EXAMFIELDS SECTION
  const [examfields, setexamfields] = useState([
    { exam: "", year: "", marks: "", rank: "" },
  ]);

  // STATE DECLARATION FOR THE personalDetails SECTION
  const initialpersonalDetails = {
    fullname: "",
    student_id: null,
    guardianname: "",
    surname: "",
    dob: "",
    socialcategory: "",
    mothertongue: "",
    gender: "",
  };
  const [personalDetails, setpersonalDetails] = useState(
    initialpersonalDetails
  );
  const [personalDetailsError, setpersonalDetailsError] = useState(
    initialpersonalDetails
  );

  // STATE DECLARATION FOR THE CONTACTDETAILS SECTION
  const initialcontactDetails = {
    mobileno: "",
    alt_mobileno: "",
    email_id: "",
    address: "",
    country: "",
    city: "",
    state: "",
    pincode: "",
  };
  const [contactDetails, setcontactDetails] = useState(initialcontactDetails);
  const [contactDetailsError, setcontactDetailsError] = useState(
    initialcontactDetails
  );

  // STATE  DECLARATION FOR THE EDUCATION DETAILS

  const initialscc = { passing_year: "", board: "", percentage: "" };
  const initialhsc = {
    passing_year: "",
    board: "",
    percentage: "",
    stream: "",
  };
  const initialug = { passing_year: "", board: "", percentage: "", stream: "" };
  const [ssc_details, setssc_details] = useState(initialscc);
  const [hsc_details, sethsc_details] = useState(initialhsc);
  const [ug_details, setug_details] = useState(initialug);

  // STATE DECALARATION FOR THE PHOTO SECTION
  const [previewImage, setpreviewImage] = useState(user);
  const [photo, setphoto] = useState(null);
  const [photoError, setphotoError] = useState("");

  //STATE DECALARATION FOR THE REFFERAL AND PROMO CODE
  const [couponCode, setcouponCode] = useState({
    couponid: "",
    referralcode: "",
  });

  //STATE DECLARATION FOR THE PAYMENT DETAILS
  //eslint-disable-next-line
  const [paymentDetails, setpaymentDetails] = useState({});

  //STATE DECLARATION FOR PDF DATA
  const [genpdf, setgenpdf] = useState({});

  // ONCHANGE FUNCTION  FOR THE PERSONALDETAILS SECTION
  const handleChange = (e) => {
    setpersonalDetails({ ...personalDetails, [e.target.name]: e.target.value });
  };
  //ONCHANGE FUNCTION  FOR THE CONTACTDETAILS SECTION
  const handleChangecontact = (e) => {
    setcontactDetails({ ...contactDetails, [e.target.name]: e.target.value });
  };
  // ONCHANGE FUNCTION  FOR THE EDUCATIONDETAILS SECTION
  const handleChangessc = (e) => {
    setssc_details({ ...ssc_details, [e.target.name]: e.target.value });
  };
  //  ONCHANGE FUNCTION  FOR THE HSC SECTION
  const handleChangehsc = (e) => {
    sethsc_details({ ...hsc_details, [e.target.name]: e.target.value });
  };
  // ONCHANGE FUNCTION  FOR THE UG SECTION
  const handleChangeug = (e) => {
    setug_details({ ...ug_details, [e.target.name]: e.target.value });
  };
  // ONCHANGE FUNCTION  FOR THE EXAM SECTION
  const handleExamChange = (e, index) => {
    const value = [...examfields];
    value[index][e.target.name] = e.target.value;
    setexamfields(value);
  };
  // ONCHANGE FUNCTION  FOR THE CART SECTION
  const handleChangecart = (e, id) => {
    let isChecked = e.target.checked;
    console.log(id, e.target.value);
    if (isChecked) {
      setcheckcourselist((oldArray) => [...oldArray, id]);
    } else {
      setcheckcourselist([...checkcourselist.filter((x) => x !== id)]);
    }
  };
  // ONCHANGE FUNCTION  FOR THE PHOTO UPLOAD SECTION
  const handleFile = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setpreviewImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    setphoto(e.target.files[0]);
  };

  //ONCHANGE FUNCTION FOR THE PROMOCODE AND REFERAL CODE
  const handleChangeCode = (e) => {
    setcouponCode({
      ...couponCode,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  //LOAD DATA BY PINCODE
  const loadByPincode = debounce(() => {
    let pincode = pincoderef.current.value;
    if (pincode.length > 0) {
      axios
        .get(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((res) => {
          if (res.data[0].Status === "Success") {
            let data = res.data[0].PostOffice[0];
            setcontactDetails({
              ...contactDetails,
              country: data.Country,
              city: data.Region,
              state: data.State,
            });
          }
        })
        .catch((err) => {
          failMsg(err);
        });
    }
  });

  //FUNCTION FOR THE EXAM SECTION
  const handleAddMore = () => {
    setexamfields([
      ...examfields,
      { exam: "", appear: "", marks: "", rank: "" },
    ]);
  };
  //FUNCTION FOR THE EXAM SECTION
  const handleRemove = (index) => {
    const value = [...examfields];
    value.splice(index, index);
    setexamfields(value);
  };

  //FUNCTION FOR THE REMOVING ITEM FROM THE CART
  const removeCartItem = (id) => {
    // addToCartButtonState(id);
    let buttonState = JSON.parse(localStorage.getItem("buttonState"));

    const value = [...buttonState];
    value[id] = !buttonState[id];

    localStorage.setItem("buttonState", JSON.stringify(value));
    if (totalUniqueItems === 1) {
      removeItem(id);
      navigate("/emptycart");
    } else {
      removeItem(id);
    }
  };

  //**** FUNTION FOR THE PAYMENT SECTION****//

  //FUNCTION FOR THE SUBMIT ALL DATA OF FORM IN DATABASE
  const applyNow = (formData) => {
    POST("user/applynow", formData)
      .then((res) => {
        console.log("applynow", res);

        if (res.serverData !== null) {
          let createorder = {
            college_id: getArrayOfCollegeid(items),
            courseslist: getArrayOfCourseList(items),
            couponid: couponCode.couponid,
            referralcode: couponCode.referralcode,
            user_id: res.serverData.student_id,
          };
          createOrder(createorder);
          setgenpdf({
            ...res.serverData.pdf_data,
            order_no: res.serverData.pdf_data.application_no,
          });
          generatePdf({
            ...res.serverData.pdf_data,
            order_no: res.serverData.pdf_data.application_no,
          });
        } else {
          warningMsg(res.error);
        }
      })
      .catch((err) => {
        failMsg(err);
      });
  };

  //FUNCTION FOR THE SUBMIT ALL DATA OF CARTS
  const createOrder = (createorder) => {
    POST("user/createorder", createorder)
      .then((res) => {
        console.log("apicreate", res);
        const data = res?.serverData?.OrderData;
        displayRazorpay(data, res?.serverData?.token);
      })
      .catch((err) => {
        failMsg(err);
      });
  };

  //FUNCTION FOR THE GENERATE PDF
  const generatePdf = (pdfdata) => {
    POST("user/generatepdf", pdfdata)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        // failMsg(err);
      });
  };

  // FUNCTION FOR THE COMPLETE PAYMENTS
  const completePayment = (
    razorpay_signature,
    razorpay_payment_id,
    razorpay_order_id,
    order_no,
    payment_token
  ) => {
    axios
      .post(
        `${REACT_APP_BASEURL}user/save`,

        // `${REACT_APP_BASEURL}user/confirmpayment`,
        // {
        //   rzp_signature:
        //     "5e6f75e9504bb6262a2279cf42c03143f26d5a49d35cc704a68c596b2d317b6f",
        //   rzp_paymentid: "pay_LAp3CSgN5xzebl",
        //   rzp_orderid: "order_LAp1TNjQMoPNZQ",
        //   order_no: "2023013191",
        // },
        {
          rzp_signature: razorpay_signature,
          rzp_paymentid: razorpay_payment_id,
          rzp_orderid: razorpay_order_id,
          order_no: order_no,
        },
        // {
        //   headers: {
        //     // Authorization: `Bearer ${payment_token}`,
        //     "csrf-token": `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOjkxLCJhdWQiOiJjb2xsZWdlcG9ydGFsLmNvbSIsImlhdCI6MTM1Njk5OTUyNCwibmJmIjoxMzU3MDAwMDAwfQ.RICtqWP3U7tkQBMfq1AO9UAcmTj4eSNlg_0FVCdrbhc`,
        //     // "access-control-allow-credentials": true,
        //     // "access-control-allow-headers":
        //     //   "Content-Type, Authorization, X-Requested-With,Cache-Control",
        //     // "access-control-allow-methods":
        //     //   "PUT, GET, HEAD, POST, DELETE, OPTIONS",
        //     // "access-control-allow-origin": "http://localhost:3000",
        //     // "access-control-max-age": 86400,
        //     // "cache-control": "no-cache",
        //     // "content-encoding": "br",
        //     // "content-length": 176,
        //     // "content-security-policy": "upgrade-insecure-requests",
        //     // "content-type": "application/json",
        //     // platform: "hostinger",
        //     // server: "LiteSpeed",
        //     // vary: "Accept-Encoding",
        //   },
        // }
      )
      .then((res) => {
        if (res.data.status_code === 200) {
          if (res.data.operation_status === true) {
            generatePdf(genpdf);
            paymentStatus(
              "success",
              "Payment Successful!",
              res.data.serverData.userdata.mobile_no,
              res.data.serverData.userdata.email_id,
              res.data.serverData.orderdata.amount,
              res.data.serverData.transaction_id
            );
          } else {
            //payment failed
            paymentStatus(
              "error",
              "Payment Failed!",
              res.data.serverData.userdata.mobile_no,
              res.data.serverData.userdata.email_id,
              res.data.serverData.orderdata.amount,
              res.data.serverData.transaction_id
            );
          }
        } else {
          failMsg(res.data.statusCodeMessage);
        }
      })
      .catch((err) => {
        failMsg(err);
      });
  };

  //FUNCTION FOR THE SUBMIT ALL THE DATA OF FORM
  const handleSubmit = () => {
    setfocus(true);
    let fd = new FormData();

    // let formData = {
    //     personaldetails: personalDetails,
    //     contactdetails: contactDetails,
    //     educationaldetails: { SSC_DETAILS: ssc_details, HSC_DETAILS: hsc_details, UG_DETAILS: ug_details },
    //     examdetails: examfields,
    //     photo: 'fd',
    //     signature: null,
    // }
    fd.append("personaldetails", JSON.stringify(personalDetails));
    fd.append("contactdetails", JSON.stringify(contactDetails));
    fd.append(
      "educationaldetails",
      JSON.stringify({
        SSC_DETAILS: ssc_details,
        HSC_DETAILS: hsc_details,
        UG_DETAILS: ug_details,
      })
    );
    fd.append("examdetails", JSON.stringify(examfields));
    fd.append("photo", photo);
    fd.append("signature", null);
    fd.append(
      "agent_code",
      agentCode === "null" ? couponCode?.referralcode : agentCode || ""
    );

    Swal.fire({
      title: "Do You Want To Add More Colleges ?",
      text: "",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/apphome");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        if (
          Object.keys(personalDetailsValidation(personalDetails)).length ===
            0 &&
          Object.keys(contactDetailsValidation(contactDetails)).length === 0 &&
          Object.keys(photoValidation(photo)).length === 0
        ) {
          console.log({
            checkcourselist,
            array: getArrayOfCourseList(items),
            items,
          });
          applyNow(fd);
          // if (items?.length === getArrayOfCourseList(items)?.length) {
          // } else {
          //   warningMsg(
          //     "Please select courses from all the added colleges to cart!"
          //   );
          // }
        } else {
          warningMsg("Please fill all required details");
        }
      }
    });
  };
  // # RAZORPAY_KEY=rzp_live_c3ADOEkUPDLQFh

  //FUNCTION FOR THE OPEN RAZOR PAY
  async function displayRazorpay(data, token) {
    const options = {
      key: "rzp_live_Xd8Um1MkoYNolV",
      // key: "rzp_test_WVzVWRbRrKOWwq",
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data?.pay_id,
      name: personalDetails.fullname,
      description: "BookMyCourse Payment",
      image: previewImage,
      handler: function (res) {
        // alert('Payment Done')
        console.log("allrzorpaydata", res);
        console.log(
          res.razorpay_payment_id,
          res.razorpay_order_id,
          res.razorpay_signature
        );
        setpaymentDetails({
          razorpay_payment_id: res.razorpay_payment_id,
          razorpay_order_id: res.razorpay_order_id,
          razorpay_signature: res.razorpay_signature,
          order_no: data.order_no,
          payment_token: token,
        });
        completePayment(
          res.razorpay_signature,
          res.razorpay_payment_id,
          res.razorpay_order_id,
          data.order_no,
          token
        );
      },
      modal: {
        ondismiss: function () {
          successMsg("Form Submitted Successfully");
        },
      },
      prefill: {
        name: "New Student",
        email: "new@gmail.com",
        contact: "9168812854",
      },
      theme: {
        color: "#F37254",
      },
    };

    console.log({ options });

    const rzp1 = new Razorpay(options);

    rzp1.open();

    // const res = await loadScript(
    //   "https://checkout.razorpay.com/v1/checkout.js"
    // );

    // if (!res) {
    //   failMsg("Razorpay SDK failed to load. Are you online?");
    //   return;
    // }

    // const options = {
    //   key: "rzp_live_g5miv5goWhNRRs",
    //   // key: "rzp_test_WVzVWRbRrKOWwq",
    //   currency: data.currency,
    //   amount: data.amount.toString(),
    //   order_id: data?.pay_id,
    //   name: personalDetails.fullname,
    //   description: "BookMyCourse Payment",
    //   image: previewImage,
    //   handler: function (res) {
    //     // alert('Payment Done')
    //     console.log("allrzorpaydata", res);
    //     console.log(
    //       res.razorpay_payment_id,
    //       res.razorpay_order_id,
    //       res.razorpay_signature
    //     );
    //     setpaymentDetails({
    //       razorpay_payment_id: res.razorpay_payment_id,
    //       razorpay_order_id: res.razorpay_order_id,
    //       razorpay_signature: res.razorpay_signature,
    //       order_no: data.order_no,
    //       payment_token: token,
    //     });
    //     completePayment(
    //       res.razorpay_signature,
    //       res.razorpay_payment_id,
    //       res.razorpay_order_id,
    //       data.order_no,
    //       token
    //     );
    //   },
    //   modal: {
    //     ondismiss: function () {
    //       successMsg("Form Submitted Successfully");
    //     },
    //   },
    //   prefill: {
    //     name: "New Student",
    //     email: "new@gmail.com",
    //     contact: "9168812854",
    //   },
    //   theme: {
    //     color: "#F37254",
    //   },
    // };
    // const paymentObject = new window.Razorpay(options);
    // paymentObject.open();
  }

  useEffect(() => {
    setbgColor(["bg-white sticky top-0 shadow-xl", "bg-black"]);
    window.scrollTo(0, 0);

    if (totalUniqueItems === 0) {
      navigate("/emptycart");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (focus) {
      setpersonalDetailsError(personalDetailsValidation(personalDetails));
      setcontactDetailsError(contactDetailsValidation(contactDetails));
      setphotoError(photoValidation(photo));
      // console.log(photo);
    }

    //eslint-disable-next-line
  }, [personalDetails, contactDetails, photo, focus]);
  return (
    <div className="p-5 bg-gray-50 ">
      {/* CONTAINER FOR THE APPLICATION FORM */}
      <div className="container mx-auto space-y-4">
        {/* SECTION FOR THE CARTS */}
        <div>
          <h1 className="my-4 text-xl font-semibold">My Cart</h1>
          <div className="px-2 py-3 bg-white rounded-md shadow-xl">
            <h1 className="my-3 text-lg font-medium text-center text-gray-500">
              Select Courses
            </h1>
            {items?.map((element, index) => (
              <div
                key={index}
                className="grid grid-cols-1 gap-4 lg:grid-cols-3 pb-7"
              >
                {/* COLLEGE SECTION OF CARTS */}
                <div className="flex gap-3">
                  <img
                    className="w-20 h-20 border rounded-md"
                    src={img}
                    alt=""
                  />
                  <div className="space-y-2 font-semibold">
                    <h5>{element.names}</h5>
                    <h4 className="text-red-600">{`Rs:${element.price}`}</h4>
                    <button
                      onClick={() => {
                        removeCartItem(element.id);
                      }}
                      className="p-2 text-sm text-gray-500 bg-gray-200 rounded-md hover:bg-gray-300 hover:text-gray-600"
                    >
                      Remove
                    </button>
                  </div>
                </div>

                {/* COURSES SECTION OF CARTS */}
                <div className="flex flex-col col-span-2 p-3">
                  {element.courses?.length > 0 ? (
                    element.courses?.map((item, index) => (
                      <label key={index} className="p-2 border rounded-md">
                        <input
                          className="mx-2"
                          type="checkbox"
                          name="course"
                          value={item.name}
                          onChange={(e) => {
                            handleChangecart(e, item.id);
                          }}
                        />
                        {item.name}
                      </label>
                    ))
                  ) : (
                    <span className="text-lg font-semibold">
                      No Courses Available
                    </span>
                  )}
                </div>
              </div>
            ))}

            <div className="flex items-center justify-between px-3 pt-2 font-semibold text-red-500 border-t">
              <button
                onClick={() => {
                  navigate("/apphome");
                }}
                className="hover:text-red-600"
              >
                Add More Colleges
              </button>
              <AiOutlinePlus
                onClick={() => {
                  navigate("/apphome");
                }}
                className="text-2xl font-bold cursor-pointer hover:text-red-600"
              />
            </div>
          </div>
        </div>

        {/* SECTION FOR THE BASIC DETAILS */}
        <div className="overflow-x-hidden transition-all duration-200 bg-white rounded-md shadow-xl card">
          <h1 className="p-2 text-2xl font-semibold text-white bg-red-500">
            Basic Details
          </h1>
          <div className="p-3">
            <h1 className="text-lg font-semibold ">
              Change basic details here.
            </h1>
            <div className="grid grid-cols-1 gap-4 p-5 lg:grid-cols-3">
              <Inputfields
                label={"First Name *"}
                type={"text"}
                name={"fullname"}
                value={personalDetails.fullname}
                onChange={handleChange}
                placeholder="Enter your name"
                error={personalDetailsError.fullname}
                inputstyle={"rounded-md"}
                labelstyle={"text-red-500"}
              />

              <Inputfields
                label={"MiddleName *"}
                type={"text"}
                name={"guardianname"}
                value={personalDetails.guardianname}
                onChange={handleChange}
                placeholder="Enter your middlename"
                error={personalDetailsError.guardianname}
                inputstyle={"rounded-md"}
                labelstyle={"text-red-500"}
              />

              <Inputfields
                label={"SurName *"}
                type={"text"}
                name={"surname"}
                value={personalDetails.surname}
                onChange={handleChange}
                placeholder="Enter your surname"
                error={personalDetailsError.surname}
                inputstyle={"rounded-md"}
                labelstyle={"text-red-500"}
              />

              <Inputfields
                label={"D.O.B"}
                type={"date"}
                name={"dob"}
                value={personalDetails.dob}
                onChange={handleChange}
                placeholder=""
                error={personalDetailsError.dob}
                inputstyle={"rounded-md"}
              />

              <Dropdown
                label={"Social Category"}
                name={"socialcategory"}
                value={personalDetails.socialcategory}
                onChange={handleChange}
                error={personalDetailsError.socialcategory}
              >
                <option>OBC</option>
                <option>General</option>
              </Dropdown>

              <div className="flex items-center space-x-4 ">
                {/* <input type={'radio'} name='gender' />Male */}
                <RadioFields
                  value={"Male"}
                  type={"radio"}
                  name={"gender"}
                  label={"Male"}
                  onChange={handleChange}
                />
                <RadioFields
                  value={"Female"}
                  type={"radio"}
                  name={"gender"}
                  label={"Female"}
                  onChange={handleChange}
                />
                <RadioFields
                  value={"Other"}
                  type={"radio"}
                  name={"gender"}
                  label={"Other"}
                  onChange={handleChange}
                />
                {/* <input type={'radio'} name='gender' />Female
                                <input type={'radio'} name='gender' />Other */}
              </div>
            </div>
          </div>
        </div>

        {/* SECTION FOR THE CONTACT DETAILS */}
        <div className="overflow-x-hidden bg-white rounded-md shadow-xl card">
          <h1 className="p-2 text-2xl font-semibold text-white bg-red-500">
            Contact Details
          </h1>
          <div className="p-3">
            <h1 className="text-lg font-semibold ">
              Always keep contact details updated, it will help us communicate
              better
            </h1>
            <div className="grid grid-cols-1 gap-4 p-5 lg:grid-cols-3">
              <Inputfields
                label={"Mobile No. *"}
                type={"number"}
                name={"mobileno"}
                value={contactDetails.mobileno}
                onChange={handleChangecontact}
                placeholder="Enter your mobile no"
                error={contactDetailsError.mobileno}
                inputstyle={"rounded-r-md"}
                labelstyle={"text-red-500"}
              >
                <span className="p-1 bg-gray-200 border border-r-0 border-black rounded-l-md group-focus-within:ring-2 group-focus-within:ring-blue-500 group-focus-within:border-r-0 group-focus-within:border-0">
                  +91
                </span>
              </Inputfields>

              <Inputfields
                label={"Email address you use *"}
                type={"email"}
                name={"email_id"}
                value={contactDetails.email_id}
                onChange={handleChangecontact}
                placeholder="Enter your Email"
                error={contactDetailsError.email_id}
                inputstyle={"rounded-md"}
                labelstyle={"text-red-500"}
              />

              <Inputfields
                refs={pincoderef}
                label={"Pincode *"}
                type={"number"}
                name={"pincode"}
                value={contactDetails.pincode}
                onChange={handleChangecontact}
                onKeyUp={loadByPincode}
                placeholder="Enter your Pincode"
                error={contactDetailsError.pincode}
                inputstyle={"rounded-md"}
                labelstyle={"text-red-500"}
              />

              <Inputfields
                label={"Country *"}
                type={"text"}
                name={"country"}
                value={contactDetails.country}
                onChange={handleChangecontact}
                placeholder="Enter your Country"
                error={contactDetailsError.country}
                inputstyle={"rounded-md"}
                labelstyle={"text-red-500"}
              />

              <Inputfields
                label={"City *"}
                type={"text"}
                name={"city"}
                value={contactDetails.city}
                onChange={handleChangecontact}
                placeholder="Enter your City"
                error={contactDetailsError.city}
                inputstyle={"rounded-md"}
                labelstyle={"text-red-500"}
              />

              <Inputfields
                label={"State *"}
                type={"text"}
                name={"state"}
                value={contactDetails.state}
                onChange={handleChangecontact}
                placeholder="Enter your State"
                error={contactDetailsError.state}
                inputstyle={"rounded-md"}
                labelstyle={"text-red-500"}
              />
            </div>
          </div>
        </div>

        {/* SECTION FOR THE EDUCATION DETAILS */}
        <div className="overflow-x-hidden transition-all duration-200 bg-white rounded-md shadow-xl card">
          <h1 className="p-2 text-2xl font-semibold text-white bg-red-500">
            Education Details
          </h1>
          <div className="p-3">
            {/* SECTION FOR THE 10th DETAILS */}
            <div className="grid grid-cols-1 gap-4 p-5 lg:grid-cols-4 ">
              <div className="flex flex-col lg:flex-row lg:items-center lg:gap-3 ">
                <span className="p-1 font-semibold text-red-500 rounded-l-md">
                  10th
                </span>
                <Dropdown
                  label={"Passing Year"}
                  name={"passing_year"}
                  value={ssc_details.passing_year}
                  onChange={handleChangessc}
                  error={""}
                >
                  <option>--Select Years--</option>
                  {getArrayOfYears().map((item, i) => (
                    <option key={i}>{item}</option>
                  ))}
                </Dropdown>
              </div>

              <Dropdown
                label={"Board"}
                name={"board"}
                value={ssc_details.board}
                onChange={handleChangessc}
                error={""}
              >
                <option>--Select Board--</option>
                {boradData.map((item, i) => (
                  <option key={i}>{item}</option>
                ))}
              </Dropdown>
              <div className="lg:col-span-2 lg:w-[49%]">
                <Inputfields
                  label={"Percentage / CGPA"}
                  type={"text"}
                  name={"percentage"}
                  value={ssc_details.percentage}
                  onChange={handleChangessc}
                  placeholder="Enter your Percentage"
                  error={""}
                  inputstyle={"rounded-md"}
                />
              </div>

              {/* SECTION FOR THE 12th DETAILS */}
              <div className="flex flex-col lg:flex-row lg:items-center lg:gap-3 mt-7 lg:mt-0">
                <span className="p-1 font-semibold text-red-500 rounded-l-md">
                  12th
                </span>
                <Dropdown
                  label={"Passing Year"}
                  name={"passing_year"}
                  value={hsc_details.passing_year}
                  onChange={handleChangehsc}
                  error={""}
                >
                  <option>--Select Years--</option>
                  {getArrayOfYears().map((item, i) => (
                    <option key={i}>{item}</option>
                  ))}
                </Dropdown>
              </div>

              <Dropdown
                label={"Board"}
                name={"board"}
                value={hsc_details.board}
                onChange={handleChangehsc}
                error={""}
              >
                <option>--Select Board--</option>
                {boradData.map((item, i) => (
                  <option key={i}>{item}</option>
                ))}
              </Dropdown>

              <Inputfields
                label={"Percentage / CGPA"}
                type={"text"}
                name={"percentage"}
                value={hsc_details.percentage}
                onChange={handleChangehsc}
                placeholder="Enter your Percentage"
                error={""}
                inputstyle={"rounded-md"}
              />

              <Dropdown
                label={"Stream"}
                name={"stream"}
                value={hsc_details.stream}
                onChange={handleChangehsc}
                error={""}
              >
                <option>--Select Stream--</option>
                {streamData.map((item, i) => (
                  <option key={i}>{item}</option>
                ))}
              </Dropdown>

              {/* SECTION FOR THE UG DETAILS */}
              <div className="flex flex-col lg:flex-row lg:items-center lg:gap-3 mt-7 lg:mt-0">
                <span className="p-1 font-semibold text-red-500 rounded-l-md">
                  UG
                </span>
                <Dropdown
                  label={"Passing Year"}
                  name={"passing_year"}
                  value={ug_details.passing_year}
                  onChange={handleChangeug}
                  error={""}
                >
                  <option>--Select Years--</option>
                  {getArrayOfYears().map((item, i) => (
                    <option key={i}>{item}</option>
                  ))}
                </Dropdown>
              </div>

              <Dropdown
                label={"University"}
                name={"board"}
                value={ug_details.board}
                onChange={handleChangeug}
                error={""}
              >
                <option>--Select Board--</option>
                {universityData.map((item, i) => (
                  <option key={i}>{item}</option>
                ))}
              </Dropdown>

              <Inputfields
                label={"Percentage / CGPA"}
                type={"text"}
                name={"percentage"}
                value={ug_details.percentage}
                onChange={handleChangeug}
                placeholder="Enter your Percentage"
                error={""}
                inputstyle={"rounded-md"}
              />
              <Dropdown
                label={"Stream"}
                name={"stream"}
                value={ug_details.stream}
                onChange={handleChangeug}
                error={""}
              >
                <option>--Select Stream--</option>
                {streamData.map((item, i) => (
                  <option key={i}>{item}</option>
                ))}
              </Dropdown>
            </div>
          </div>
        </div>

        {/* SECTION FOR THE EXAM DETAILS */}
        <div className="overflow-x-hidden transition-all duration-200 bg-white rounded-md shadow-xl card">
          <h1 className="p-2 text-2xl font-semibold text-white bg-red-500">
            Exam Details
          </h1>
          <div className="p-3">
            <h1 className="text-lg font-semibold ">
              Sharing exam results could take you a step closer to dream college
            </h1>
            <div className="grid items-center grid-cols-1 gap-4 p-5 overflow-y-auto lg:grid-cols-5 max-h-80 ">
              {examfields.map((item, index) => (
                <>
                  <Dropdown
                    label={"Select exam"}
                    name={"exam"}
                    value={item.exam}
                    onChange={(e) => {
                      handleExamChange(e, index);
                    }}
                    error={""}
                  >
                    <option>--Select Exam--</option>
                    {examData.map((item, i) => (
                      <option key={i}>{item}</option>
                    ))}
                  </Dropdown>

                  <Dropdown
                    label={"Appread/Appreaing"}
                    name={"year"}
                    value={item.appear}
                    onChange={(e) => {
                      handleExamChange(e, index);
                    }}
                    error={""}
                  >
                    <option>--Select Years--</option>
                    {getArrayOfYears().map((item, i) => (
                      <option key={i}>{item}</option>
                    ))}
                  </Dropdown>

                  <Inputfields
                    label={"Marks Obtained"}
                    type={"text"}
                    name={"marks"}
                    value={item.marks}
                    onChange={(e) => {
                      handleExamChange(e, index);
                    }}
                    placeholder="Enter your Marks"
                    error={""}
                    inputstyle={"rounded-md"}
                  />

                  <Inputfields
                    label={"Rank"}
                    type={"number"}
                    name={"rank"}
                    value={item.rank}
                    onChange={(e) => {
                      handleExamChange(e, index);
                    }}
                    placeholder="Enter your Rank"
                    error={""}
                    inputstyle={"rounded-md"}
                  />

                  <button
                    onClick={() => {
                      handleRemove(index);
                    }}
                    className="flex items-center justify-center w-24 h-10 py-2 text-white bg-blue-500 rounded-md"
                  >
                    Remove
                  </button>
                </>
              ))}
            </div>
            <button
              onClick={handleAddMore}
              className="flex items-center px-3 py-2 my-3 text-white bg-blue-500 rounded-md"
            >
              <AiOutlinePlus className="text-xl" /> Add More
            </button>
          </div>
        </div>

        {/* SECTION FOR THE PHOTO DETAILS */}
        <div className="overflow-x-hidden transition-all duration-200 bg-white rounded-md shadow-xl card">
          <h1 className="p-2 text-2xl font-semibold text-white bg-red-500">
            Photo{" "}
          </h1>
          <div className="p-3">
            <h1 className="text-lg font-semibold ">
              Upload Passport Size Photograph
            </h1>
            <img
              className="w-40 h-40 border-2 rounded-md "
              src={previewImage}
              alt=""
            />
            <div className="grid grid-cols-1 gap-4 p-5 overflow-y-auto lg:grid-cols-4 ">
              <Inputfields
                label={"Upload Photo *"}
                type={"file"}
                name={"photo"}
                onChange={handleFile}
                placeholder=""
                error={photoError}
                accept={"image/*"}
                inputstyle={"rounded-md"}
                labelstyle={"text-red-500"}
              />
            </div>
          </div>
        </div>

        {/* SECTION FOR THE PROMO CODE */}
        <div className="bg-white shadow-xl rounded-md  p-5 lg:w-[70%] mx-auto">
          <h2 className="flex font-semibold text-gray-400 mb-7">
            APPLY PROMOCODE<p className="text-green-500">(OPTIONAL)</p>{" "}
          </h2>
          <div className="flex items-center  w-[80%] lg:w-[50%] gap-3">
            <Inputfields
              label={"Apply promo code"}
              type={"text"}
              name={"couponid"}
              value={couponCode.couponid}
              onChange={handleChangeCode}
              placeholder="Enter your code"
              error={""}
              inputstyle={"rounded-md uppercase"}
            />
            <button className="flex items-center px-4 py-2 text-white bg-blue-500 rounded-md">
              {" "}
              Apply
            </button>
          </div>
        </div>

        {/* SECTION FOR THE REFERRAL  CODE */}
        <div className="bg-white shadow-xl rounded-md  p-5 lg:w-[70%] mx-auto">
          <h2 className="flex font-semibold text-gray-400 mb-7">
            USE REFERRAL CODE<p className="text-green-500">(OPTIONAL)</p>{" "}
          </h2>
          <div className="flex items-center  w-[80%] lg:w-[50%] gap-3">
            <Inputfields
              label={"Use Referral Code"}
              type={"text"}
              name={"referralcode"}
              value={couponCode.referralcode}
              onChange={handleChangeCode}
              placeholder="Enter your code"
              error={""}
              inputstyle={"rounded-md uppercase"}
            />
          </div>
        </div>
        {/* SECTION FOR THE PAYMENT PROCEED */}
        <div className="bg-white shadow-xl rounded-md  p-5 lg:w-[70%] mx-auto">
          <h2 className="flex font-semibold text-gray-400 mb-7">
            PAYMENT DETAILS
          </h2>
          <div className="flex items-center justify-between gap-3 font-semibold">
            <div className="space-y-5">
              <h4>Total Amount *</h4>
              <div>
                <h5 className="text-sm text-gray-400 ">TOTAL AMOUNT</h5>
                <h3>Rs:{getArrayOfprice(items)}.00</h3>
              </div>
            </div>
            <div className="w-[50%] flex flex-col space-y-6 items-end">
              <h3 className="text-sm">Rs:{getArrayOfprice(items)}.00</h3>
              <button
                onClick={handleSubmit}
                className="w-full p-2 text-white bg-red-500 rounded-md hover:bg-red-600"
              >
                PROCEED
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applicationform;
