import React, { useRef } from 'react'
import imgbanner from '../../../assets/images//imgbanner.png'
import LoanForm from '../../Homepage/LoanForm';

const Loan = () => {
    const loanForm = useRef();
    const handleLoanForm = () => {
        loanForm.current.classList.toggle('formhide');
    }
    return (
        <div className='bg-white shadow-xl rounded-md p-10'>
            <div className='border-4 border-blue-300 p-5 flex items-center justify-center'>
                <div className='space-y-5'>
                    <h5 className='font-semibold'>Worried About College Fees?</h5>
                    <p className='text-sm'>Convert your Upfront Fees into Simple, Convenient & Affordable  EMIs</p>
                    <button onClick={handleLoanForm} className='bg-red-400 px-2 py-4 text-white hover:bg-red-500 rounded-md shadow-lg'>Apply For Education Loan</button>
                </div>
                <img className='hidden lg:block' src={imgbanner} alt='' />

            </div>
            <LoanForm loanref={loanForm} handleLoanForm={handleLoanForm} />
        </div>
    )
}

export default Loan