import React, { useEffect } from "react";
import { FaUserAlt } from "react-icons/fa";
import { useOutletContext } from "react-router-dom";
import { BiUser } from "react-icons/bi";

const Profile = () => {
  const [setbgColor] = useOutletContext();

  useEffect(() => {
    setbgColor(["bg-white sticky shadow-xl", "bg-black"]);
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, []);
  return (
    <>
      {/* HEADING UI */}
      <div className="w-full h-52 px-10 bg-red-400 flex items-center">
        <div className="bg-white rounded-full w-24 h-24 flex justify-center items-center">
          <FaUserAlt className="w-12 h-12" />
        </div>
        <div className="text-2xl gap-4 px-3 font-semibold text-white  flex">
          <h2>Welcome,</h2>
          <h3>Admin</h3>
        </div>
      </div>
      {/* BODY */}
      <div className="grid grid-cols-5 min-h-[20rem] ">
        <div className="p-5">
          <ul className="border shadow-md bg-white rounded-md p-3">
            <li className="flex items-center  gap-2 text-blue-500 bg-zinc-200 hover:text-blue-500 hover:bg-zinc-200 p-2 cursor-pointer rounded-md">
              <BiUser /> Profile
            </li>
          </ul>
        </div>
        <div className="col-span-4 p-5 space-y-5 capitalize">
          {/* BASIC DETAILS */}
          <div className="p-8 border space-y-4 bg-white rounded-md shadow-md">
            <h2 className="font-semibold text-lg">Basic Deatils</h2>
            <div className="grid grid-cols-3">
              <div className="text-base">
                <div className="text-zinc-500">First Name</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">Middle Name</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">SurName</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">Date of Birth</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">Social Category</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">Gender</div>
                <div className="font-semibold">value</div>
              </div>
            </div>
          </div>

          {/* CONTACT DETAILS */}
          <div className="p-8 border space-y-4 bg-white rounded-md shadow-md">
            <h2 className="font-semibold text-lg">Contact Deatils</h2>
            <div className="grid grid-cols-3">
              <div className="text-base">
                <div className="text-zinc-500">Mobile No.</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">Email Id</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">Pincode</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">Country</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">city</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">State</div>
                <div className="font-semibold">value</div>
              </div>
            </div>
          </div>

          {/* EDUCATION DETAILS */}
          <div className="p-8 border space-y-4 bg-white rounded-md shadow-md">
            <h2 className="font-semibold text-lg">Education Deatils</h2>
            <div className="space-y-3">
              <h2 className="font-semibold text-lg text-slate-400">class X</h2>
              <div className="grid grid-cols-3">
                <div className="text-base">
                  <div className="text-zinc-500">Mobile No.</div>
                  <div className="font-semibold">value</div>
                </div>
                <div className="text-base">
                  <div className="text-zinc-500">Email Id</div>
                  <div className="font-semibold">value</div>
                </div>
                <div className="text-base">
                  <div className="text-zinc-500">Pincode</div>
                  <div className="font-semibold">value</div>
                </div>
                <div className="text-base">
                  <div className="text-zinc-500">Country</div>
                  <div className="font-semibold">value</div>
                </div>
                <div className="text-base">
                  <div className="text-zinc-500">city</div>
                  <div className="font-semibold">value</div>
                </div>
                <div className="text-base">
                  <div className="text-zinc-500">State</div>
                  <div className="font-semibold">value</div>
                </div>
              </div>
            </div>
          </div>

          {/* EXAM DETAILS */}
          <div className="p-8 border space-y-4 bg-white rounded-md shadow-md">
            <h2 className="font-semibold text-lg">Exam Deatils</h2>
            <div className="grid grid-cols-4">
              <div className="text-base">
                <div className="text-zinc-500">Exam Name</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">Appread/Appearing Year</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">Marks Obtained</div>
                <div className="font-semibold">value</div>
              </div>
              <div className="text-base">
                <div className="text-zinc-500">Rank</div>
                <div className="font-semibold">value</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
