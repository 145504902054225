import React from "react";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import Inputfields from "../../components/Inputfields";
import Logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import useForm from "../../CustomHook/Form/useForm";
import { agentValidation } from "./file";
import usePost from "../../CustomHook/usePost";

const AgentSignUp = () => {
  const navigate = useNavigate();
  const initialForm = {
    name: "",
    mobile_no: "",
    email_id: "",
    age: "",
    gender: "",
    aadhar: "",
    address: "",
    password: "",
  };

  const { createData, isLoading } = usePost(`agent/resigtration`);

  const { formObject, fieldError, handleChange, handleSubmit } = useForm({
    initialValue: initialForm,
    callback: () => {
      createData(formObject, () => {
        navigate("/");
      });
    },
    validate: agentValidation,
  });

  return (
    <div className=" w-full h-screen flex flex-col justify-center items-center py-5 bg-gradient-to-r from-slate-200 to-slate-100">
      <div className="w-2/3 flex items-center flex-col border shadow-md p-3 rounded-md bg-white">
        <img className="w-28 h-12" src={Logo} alt="" />
        {/* <h2 className="font-bold text-xl">Welcome Back !</h2> */}
        <p className="font-bold text-xl">Sign up to Continue</p>
        {/* <form className="w-full"> */}
        <div className=" w-full px-2 grid grid-cols-2 gap-5 overflow-auto">
          <Inputfields
            label={"Name"}
            type={"text"}
            name={"name"}
            value={formObject.name}
            onChange={handleChange}
            placeholder="Enter your Name"
            error={fieldError.name}
            inputstyle={"rounded-md"}
            required={true}
          />
          <Inputfields
            label={"Email"}
            type={"email"}
            name={"email_id"}
            value={formObject.email_id}
            onChange={handleChange}
            placeholder="Enter your Email"
            error={fieldError.email_id}
            inputstyle={"rounded-md"}
            required={true}
          />
          <Inputfields
            label={"Password"}
            type={"password"}
            name={"password"}
            value={formObject.password}
            onChange={handleChange}
            placeholder="Enter your Password"
            error={fieldError.password}
            inputstyle={"rounded-md"}
            required={true}
          />
          <Inputfields
            label={"Mobile no."}
            type={"number"}
            name={"mobile_no"}
            value={formObject.mobile_no}
            onChange={handleChange}
            placeholder="Enter your Mobile no."
            error={fieldError.mobile_no}
            inputstyle={"rounded-md"}
            required={true}
          />
          <Inputfields
            label={"Age"}
            type={"number"}
            name={"age"}
            value={formObject.age}
            onChange={handleChange}
            placeholder="Enter your Age"
            error={fieldError.age}
            inputstyle={"rounded-md"}
            required={true}
          />
          <Inputfields
            label={"Aadhar"}
            type={"number"}
            name={"aadhar"}
            value={formObject.aadhar}
            onChange={handleChange}
            placeholder="Enter your Aadhar no"
            error={fieldError.aadhar}
            inputstyle={"rounded-md"}
            required={true}
          />
          <Inputfields
            label={"Address"}
            type={"text"}
            name={"address"}
            value={formObject.address}
            onChange={handleChange}
            placeholder="Enter your Address"
            error={fieldError.address}
            inputstyle={"rounded-md"}
            required={true}
          />

          <Dropdown
            label={"Gender"}
            name={"gender"}
            value={formObject.gender}
            onChange={handleChange}
            error={fieldError.gender}
            required={true}
          >
            <option value={""}>--Select Gender--</option>
            <option value={"Male"}>Male</option>
            <option value={"Female"}>Female</option>
          </Dropdown>
        </div>

        <Button
          disabled={isLoading}
          onClick={() => {
            handleSubmit();
          }}
          label="Submit"
        />

        <p className="text-center mt-5">
          Already have Account ?
          <span
            onClick={() => navigate(-1)}
            className="text-blue-500 cursor-pointer hover:underline"
          >
            Login
          </span>
        </p>
        {/* </form> */}
      </div>
    </div>
  );
};

export default AgentSignUp;
