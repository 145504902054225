import React from 'react'
import spinner from '../assets/images/spinner.svg'

const Spinner = ({ isLoading,className }) => {
    return (
        <>
            {isLoading && <div className={`${className} w-full flex justify-center`}>
                <img src={spinner} alt='' />
            </div>}
        </>
    )
}

export default Spinner