import React, { useEffect, useState } from 'react'
import { useOutletContext, Link, useNavigate } from "react-router-dom";
import RadioFields from '../../components/RadioFields';
import { FaFilter } from 'react-icons/fa';
import { GET, POST } from '../../axiosHelper/Helper';
import { addToCartButtonState, changeKeyNameOfObject, failMsg, removeWhitespace } from '../../utilites/Notifcation';
import Spinner from '../../components/Spinner'
import { useCart } from "react-use-cart";
import NoData from '../../components/NoData';

const CollegeList = ({ filterClg }) => {
    const [setbgColor] = useOutletContext();
    const { REACT_APP_IMGBASEURL, REACT_APP_BASEURL } = process.env;
    const [collegeList, setcollegeList] = useState([]);
    const [isLoading, setisLoading] = useState(true)
    const [showfilter, setshowfilter] = useState(false);
    const [streamfilter, setstreamfilter] = useState(null);
    const [degreefilter, setdegreefilter] = useState([]);
    const [statefilter, setstatefilter] = useState(null);

    const [statefilterData, setstatefilterData] = useState([])
    const [degreefilterData, setdegreefilterData] = useState([])

    const navigate = useNavigate();

    const { addItem } = useCart();

    const loadClgByStream = (id) => {
        GET(`user/filter/${id}`)
            .then(res => {
                // console.log('stream', res);
                setcollegeList(res.serverData);
            })
            .catch(err => {
                failMsg(err);
            }).finally(() => {
                setisLoading(false);
            })
    }

    const loadClgCourse = (type, id) => {
        POST(`user/filter`, { filtertype: type, filter_id: id })
            .then(res => {

                setcollegeList(res.serverData);
            })
            .catch(err => {
                failMsg(err);
            })
            .finally(() => {
                setisLoading(false);
            })
    }


    const handleApplyNow = (item) => {
        let cartData;
        addToCartButtonState(item.id);
        GET(`${REACT_APP_BASEURL}user/course/${item.id}`)
            .then(res => {
                // let cartData = Object.assign(item, res.data.serverData)
                if (res.status_code === 200) {
                    cartData = { ...item, courses: res.serverData };
                    addItem(cartData);
                    navigate('/application')


                }
            })
            .catch(err => {
                failMsg(err);
            })
    }

    const handleChangeStream = (e) => {

        loadClgByStream(e.target.value);
    }

    const handleChangeDegree = (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            setdegreefilterData((oldArray) => [...oldArray, e.target.value]);
        }
        else {
            setdegreefilterData([...degreefilterData.filter((id) =>
                id !== e.target.value)])
        }
    }

    const handleChangeState = (e) => {

        let isChecked = e.target.checked;
        if (isChecked) {
            setstatefilterData((oldArray) => [...oldArray, e.target.value]);
        }
        else {
            setstatefilterData([...statefilterData.filter((id) =>
                id !== e.target.value)])
        }
    }

    // LOAD THE FILTERS DATA
    const loadstreamfilter = () => {
        GET(`user/stream`)
            .then(res => {
                setstreamfilter(res.serverData);
            })
            .catch(err => {
                failMsg(err);
            })
    }

    const loaddegreefilter = () => {
        GET(`user/degree`)
            .then(res => {
                setdegreefilter(res.serverData);
            })
            .catch(err => {
                failMsg(err);
            })
    }
    const loadstatefilter = () => {
        GET(`user/state`)
            .then(res => {
                setstatefilter(res.serverData);
            })
            .catch(err => {
                failMsg(err);
            })
    }

    useEffect(() => {
        setbgColor(['bg-white sticky shadow-xl', 'bg-black'])
        window.scrollTo(0, 0);
        loadstreamfilter();
        loaddegreefilter();
        loadstatefilter();
        loadClgonload();

        // eslint-disable-next-line
    }, [])

    const loadClgonload = () => {
        if (filterClg.length === 0) {
            navigate('/');
        }
        if (filterClg[0] === 'degree') {
            loadClgCourse(filterClg[0], [filterClg[1]])
        }
        else {
            loadClgByStream(filterClg[1]);
        }
    }

    useEffect(() => {
        if (statefilterData.length > 0) {
            loadClgCourse('state', statefilterData);
        }
        else {
            loadClgonload();
        }
    }, [statefilterData])

    useEffect(() => {
        if (degreefilterData.length > 0) {
            loadClgCourse('degree', degreefilterData);
        }
        else {
            loadClgonload();
        }

    }, [degreefilterData])



    return (
        <div className='bg-gray-100 min-h-[calc(100vh-7.3rem)]' >
            <div className='container  mx-auto p-5 '>
                <div className='flex justify-center items-center text-[#54ACAC] font-bold text-xl my-3 p-5 border bg-white'>
                    <p>College in India</p>
                </div>
                <div className='grid grid-cols-7 '>
                    {/* SIDEBAR OF COLLEGELIST  */}
                    <div className={`sidbarfilter ${showfilter ? 'block' : 'hidden'}  bg-gray-100  w-full overflow-y-auto  lg:block lg:col-span-2 col-span-7 space-y-6 lg:pr-5 pb-5`}>
                        <div className='flex justify-end items-center text-lg lg:hidden '>
                            <button onClick={() => { setshowfilter(false) }} className='bg-red-500 px-4 py-2 rounded-md text-white'>Apply</button>
                        </div>

                        <div className='bg-white flex flex-col gap-1 rounded-md max-h-[23.6rem] py-2 overflow-y-auto '>
                            <div className='font-semibold p-3'>
                                <p className='uppercase'>stream</p>

                            </div>
                            {streamfilter === null && <NoData />}
                            {
                                streamfilter?.map((item, i) => (
                                    <RadioFields key={i} type={'radio'} value={item.id} onChange={handleChangeStream} name={'stream'} label={item.name} />
                                ))
                            }

                        </div>

                        <div className='bg-white flex flex-col gap-1 rounded-md max-h-[23.6rem] py-2 overflow-y-auto'>
                            <div className='font-semibold flex flex-col space-y-3 p-3'>
                                <p className='uppercase'>Degree</p>
                                {/* <input type={'search'} name='degreesearch' placeholder='Search' className='p-2 bg-gray-100 border outline-none' /> */}
                            </div>
                            {degreefilter.length === 0 && <NoData />}
                            {
                                degreefilter?.map((item, i) => (
                                    <RadioFields key={i} value={item.id} onChange={handleChangeDegree} type={'checkbox'} name={'degree'} label={item.degree_name} />
                                ))
                            }


                        </div>
                        <div className='bg-white flex flex-col gap-1 rounded-md max-h-[23.6rem] py-2 overflow-y-auto'>
                            <div className='font-semibold flex flex-col space-y-3 p-3'>
                                <p className='uppercase'>State</p>
                                {/* <input type={'search'} name='degreesearch' placeholder='Search' className='p-2 bg-gray-100 border outline-none' /> */}
                            </div>
                            {statefilter === null && <NoData />}
                            {
                                statefilter?.map((item, i) => (
                                    <RadioFields key={i} value={item.id} onChange={handleChangeState} type={'checkbox'} name={'state'} label={item.state_name} />
                                ))
                            }

                        </div>

                    </div>
                    {/* CONTENT SECTION FOR THE COLLEGELIST */}
                    <div className={` ${!showfilter ? 'grid' : 'hidden'} grid lg:block lg:col-span-5 space-y-6 col-span-7`}>
                        <div onClick={() => { setshowfilter(true) }} className='flex justify-end items-center text-lg lg:hidden '>
                            <button className='bg-red-500 px-4 py-2 rounded-md text-white flex space-x-2 items-center'><FaFilter /><p>Advance Filter</p></button>
                        </div>
                        {collegeList?.length === 0 || collegeList === null ? (<NoData />) : (
                            changeKeyNameOfObject(collegeList)?.map((item, index) => (
                                <div key={index} className={`card flex p-4 bg-white  flex-wrap  justify-between flex-col lg:flex-row  space-y-6 lg:space-y-0 shadow-lg rounded-md`}>
                                    <div className='flex flex-col  gap-3' >
                                        <div className='flex text-base lg:text-lg '>
                                            <img className='w-20 h-20 lg:h-20  border-2 border-white  p-1 bg-gray-100' src={`${REACT_APP_IMGBASEURL}${item.logo}`} alt='College Logo' />
                                            <div>
                                                <Link to={`/collegedetail/${removeWhitespace(item.names)}/overview/${item.id}`} className='font-semibold text-2xl'>{item.names}</Link>
                                                <div className='flex flex-wrap gap-2  mb-3 text-sm lg:text-base  '>
                                                    <div className='flex  text-gray-400'>
                                                        Approved By:<p className='uppercase pl-1'>{item.approved_by}</p>
                                                    </div>
                                                    <div className='flex text-gray-400'>
                                                        Type:<p className='capitalize pl-1'>private</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='grid grid-cols-2 gap-2  md:grid-cols-3 lg:grid-cols-5 text-base  text-center'>
                                            <Link className=' border border-red-500 rounded-md text-red-500 hover:bg-red-500 hover:text-white px-2 py-1 w-full' to={`/collegedetail/${removeWhitespace(item.names)}/courses/${item.id}`}>Courses</Link>
                                            <Link className='border border-red-500 rounded-md text-red-500 hover:bg-red-500 hover:text-white px-2 py-1 w-full' to={`/collegedetail/${removeWhitespace(item.names)}/gallery/${item.id}`}>Gallery</Link>
                                            <Link className='border border-red-500 rounded-md text-red-500 hover:bg-red-500 hover:text-white px-2 py-1 w-full' to={`/collegedetail/${removeWhitespace(item.names)}/campus/${item.id}`}>Campus</Link>
                                            <Link className='border border-red-500 rounded-md text-red-500 hover:bg-red-500 hover:text-white px-2 py-1 w-full' to={`/collegedetail/${removeWhitespace(item.names)}/newdetail/${item.id}`}>News</Link>
                                            <Link className='border border-red-500 rounded-md text-red-500 hover:bg-red-500 hover:text-white px-2 py-1 w-full' to={`/collegedetail/${removeWhitespace(item.names)}/placement/${item.id}`}>Placements</Link>
                                        </div>

                                    </div>
                                    <div className='flex justify-end items-center'>
                                        <button onClick={() => { handleApplyNow(item) }} className='bg-red-500 p-3 text-white font-semibold rounded-lg '>Apply Now</button>
                                    </div>
                                </div>
                            )
                            ))}

                        <Spinner isLoading={isLoading} />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollegeList