import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useCart } from 'react-use-cart'
import emptycart from '../assets/images/emptycart.webp'

const Emptycart = () => {
  const {totalUniqueItems}= useCart();
  const navigate = useNavigate();
  useEffect(() => {
    if (totalUniqueItems >0) {
      navigate('/application');
    }
    //eslint-disable-next-line
  }, [])
  return (
    <div className='container flex flex-col justify-center items-center p-9 gap-y-3'>
      <img className='w-96 h-96' src={emptycart} alt='' />
      <h1 className='font-semibold text-2xl'>Your Cart Is Empty</h1>
      <p className='text-gray-500'>You have no items added in the cart.</p>
      <Link to={'/apphome'} className='uppercase bg-red-500 text-white py-2 px-10 rounded-md hover:bg-red-600'>Select College</Link>


    </div>
  )
}

export default Emptycart