import axios from "axios";
import { useEffect, useState } from "react";
import { failMsg } from "../utilites/Notifcation";
import { BaseUrl, header } from "./file";

const useGet = (url) => {
  const [data, setdata] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const reFetch = () => {
    axios
      .get(`${BaseUrl}${url}`, header)
      .then((res) => {
        setdata(res.data);
      })
      .catch((err) => failMsg(err))
      .finally(() => setisLoading(false));
  };

  useEffect(() => {
    setisLoading(true);
    reFetch();
  }, [url]);

  return { data, isLoading, reFetch };
};

export default useGet;

