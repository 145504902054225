import React, { useEffect, useState } from "react";
import usePost from "../../CustomHook/usePost";
import Logo from "../../assets/images/logo.png";
import Inputfields from "../Inputfields";
import Button from "../Button";
import useAuth from "../../CustomHook/useAuth";
import { useNavigate } from "react-router-dom";

const Login = ({
  handleCurrentForm,
  handleModalClose,

}) => {
  const initialForm = {
    userid: "",
    password: "",
  };
  const navigate = useNavigate()

  // LOGIN SECTION
  const [formData, setformData] = useState(initialForm);
  // const [loginError, setloginError] = useState(initialLoginForm);

  const loginChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  // API CALL

  // const { createData, isLoading, data } = usePost(`user/student/login`);
  const { createData, isLoading } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    createData(formData, () => {
      handleModalClose();
    });
  };

  // useEffect(() => {
  //   if (data?.serverData !== null && data !== null) {
  //     sessionStorage.setItem("userName", data?.serverData?.userData?.name);
  //     handleUserName(data?.serverData?.userData?.name);
  //     verifyauth(true);
  //     handleModalClose();
  //   }
  // }, [data]);

  return (
    <>
      <img className="w-28 h-12" src={Logo} alt="" />
      <h2 className="font-bold text-xl">Welcome Back !</h2>
      <p>Sign in to Continue</p>
      <form onSubmit={handleSubmit} className="w-full">
        <Inputfields
          label={"Mobile no. or Email Id"}
          type={"text"}
          name={"userid"}
          value={formData.userid}
          onChange={loginChange}
          placeholder="Enter your Mobile no. or Email Id"
          // error={loginError.userid}
          inputstyle={"rounded-md"}
          required={true}
        />
        <Inputfields
          label={"Password"}
          type={"password"}
          name={"password"}
          value={formData.password}
          onChange={loginChange}
          placeholder="Enter your password"
          // error={loginError.password}
          inputstyle={"rounded-md"}
          required={true}
        />

        <Button disabled={isLoading} label="submit" />
        <Button onClick={() => handleCurrentForm(1)} label="Login via OTP" />

        <p className="text-center mt-5">
          New to BookMyCourse ?
          <span
            onClick={() => handleCurrentForm(2)}
            className="text-blue-500 cursor-pointer hover:underline pl-2"
          >
            Sign Up
          </span>
        </p>
        <p className="text-center mt-5">
          Agent Sing Up ?
          <span
            onClick={() => navigate('/agentsignup')}
            className="text-blue-500 cursor-pointer hover:underline pl-2"
          >
            Sign Up
          </span>
        </p>
      </form>
    </>
  );
};

export default Login;
