import { createContext, useState, useEffect } from "react";
import usePost from "../CustomHook/usePost";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [userName, setuserName] = useState(null);
  const { createData, data, isLoading } = usePost(`user/student/login`);

  useEffect(() => {
    if (data?.serverData !== null && data !== null) {
      sessionStorage.setItem("userName", data?.serverData?.userData?.name);
      setuserName(sessionStorage.getItem("userName"));
    }
  }, [data]);

  const logOut = () => {
    sessionStorage.removeItem("userName");
    setuserName(null);
  };

  return (
    <AuthContext.Provider
      value={{
        createData,
        logOut,
        userName,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
