import React, { useState } from "react";
import Button from "../Button";
import Inputfields from "../Inputfields";
import otpimg from "../../assets/images/otpverifyimg.jpg";
import usePost from "../../CustomHook/usePost";

const OtpVerify = ({ handleCurrentForm }) => {
  const [mobile_no, setmobile_no] = useState("");
  const [otpData, setotpData] = useState({ otp: "", id: "" });
  const [currentForm, setcurrentForm] = useState(0);

  const { createData, isLoading, data } = usePost(`user/student/sendotp`);
  const { createData: verifyOtpByapi } = usePost(`/user/student/verify`);

  const getOtp = (e) => {
    e.preventDefault();
    createData({ mobile_no });
    setcurrentForm(1);
  };

  const handleVerifyOtpChange = (e) => {
    setotpData({ ...otpData, [e.target.name]: e.target.value, id: data?.serverData?.id });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    verifyOtpByapi(otpData);
  };
  return (
    <>
      <img className="w-28 h-28" src={otpimg} alt="" />
      <h2 className="font-bold text-xl">OTP Verification</h2>
      <p>We will send you an one time password on your mobile number</p>
      {currentForm === 0 && (
        <form onSubmit={getOtp} className="w-full">
          <Inputfields
            label={"Mobile no."}
            type={"number"}
            name={"mobile_no"}
            value={mobile_no}
            onChange={(e) => setmobile_no(e.target.value)}
            placeholder="Enter your Mobile no."
            // error={formError.name}
            inputstyle={"rounded-md"}
            required={true}
          />

          <Button onClick={"handleSubmit"} label="Generate OTP" />
          <Button
            onClick={() => handleCurrentForm(0)}
            label="Login via Password"
          />

          <p className="text-center mt-5">
            New to BookMyCourse ?
            <span
              onClick={() => handleCurrentForm(2)}
              className="text-blue-500 cursor-pointer hover:underline"
            >
              Sign Up
            </span>
          </p>
        </form>
      )}
      {currentForm === 1 && (
        <form onSubmit={verifyOtp} className="w-full">
          <Inputfields
            label={"OTP"}
            type={"number"}
            name={"otp"}
            value={otpData.otp}
            onChange={handleVerifyOtpChange}
            placeholder="Enter OTP No."
            // error={formError.name}
            inputstyle={"rounded-md"}
            required={true}
          />

          <Button label="Verify OTP" />
          <Button onClick={() => setcurrentForm(0)} label="Back" />

          <p className="text-center mt-5">
            New to BookMyCourse ?
            <span
              onClick={() => handleCurrentForm(2)}
              className="text-blue-500 cursor-pointer hover:underline"
            >
              Sign Up
            </span>
          </p>
        </form>
      )}
    </>
  );
};

export default OtpVerify;
