import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import profileImg from "../assets/images/Allicons.webp";

export default function TestimonialsSlider() {
  return (
    <Swiper
      loop={true}
      loopFillGroupWithBlank={true}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[EffectFade, Autoplay, Pagination]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="text-center px-8 transition-all mb-7">
          <span className="rounded-full   w-[100px] h-[100px] inline-block mb-5 overflow-hidden bg-no-repeat " style={{ backgroundImage: `url(${profileImg})`, backgroundPosition: "-1228px -936px" }}></span>
          <p className="font-light text-sm mb-10 text-[#333]">
            The whole process of my admission was very smooth with BookMyCourse's help; I got step-by-step guidance at every stage and they answered every doubt and guided me towards the right college and stream. Just one advice for everyone, if you have no idea for college, just trust them
            (BookMyCourse) & you will get the best.
          </p>
          <h5 className="text-[#c00404] text-base font-bold">Iliyas Shakir Ansari - Indus University</h5>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="text-center px-8 transition-all ">
          <span className="rounded-full   w-[100px] h-[100px] inline-block mb-5 overflow-hidden bg-no-repeat " style={{ backgroundImage: `url(${profileImg})`, backgroundPosition: "-1228px -936px" }}></span>
          <p className="font-light text-sm mb-10 text-[#333]">
           hello
          </p>
          <h5 className="text-[#c00404] text-base font-bold">Iliyas Shakir Ansari - Indus University</h5>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="text-center px-8 transition-all mb-7">
          <span className="rounded-full   w-[100px] h-[100px] inline-block mb-5 overflow-hidden bg-no-repeat " style={{ backgroundImage: `url(${profileImg})`, backgroundPosition: "-1228px -936px" }}></span>
          <p className="font-light text-sm mb-10 text-[#333]">
            The whole process of my admission was very smooth with BookMyCourse's help; I got step-by-step guidance at every stage and they answered every doubt and guided me towards the right college and stream. Just one advice for everyone, if you have no idea for college, just trust them
            (BookMyCourse) & you will get the best.
          </p>
          <h5 className="text-[#c00404] text-base font-bold">Iliyas Shakir Ansari - Indus University</h5>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="text-center px-8 transition-all ">
          <span className="rounded-full   w-[100px] h-[100px] inline-block mb-5 overflow-hidden bg-no-repeat " style={{ backgroundImage: `url(${profileImg})`, backgroundPosition: "-1228px -936px" }}></span>
          <p className="font-light text-sm mb-10 text-[#333]">
           hello
          </p>
          <h5 className="text-[#c00404] text-base font-bold">Iliyas Shakir Ansari - Indus University</h5>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="text-center px-8 transition-all mb-7">
          <span className="rounded-full   w-[100px] h-[100px] inline-block mb-5 overflow-hidden bg-no-repeat " style={{ backgroundImage: `url(${profileImg})`, backgroundPosition: "-1228px -936px" }}></span>
          <p className="font-light text-sm mb-10 text-[#333]">
            The whole process of my admission was very smooth with BookMyCourse's help; I got step-by-step guidance at every stage and they answered every doubt and guided me towards the right college and stream. Just one advice for everyone, if you have no idea for college, just trust them
            (BookMyCourse) & you will get the best.
          </p>
          <h5 className="text-[#c00404] text-base font-bold">Iliyas Shakir Ansari - Indus University</h5>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="text-center px-8 transition-all ">
          <span className="rounded-full   w-[100px] h-[100px] inline-block mb-5 overflow-hidden bg-no-repeat " style={{ backgroundImage: `url(${profileImg})`, backgroundPosition: "-1228px -936px" }}></span>
          <p className="font-light text-sm mb-10 text-[#333]">
           hello
          </p>
          <h5 className="text-[#c00404] text-base font-bold">Iliyas Shakir Ansari - Indus University</h5>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
