import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar/Navbar";
import Navbar2 from "./Navbar/Navbar2";
const Mainlayout = () => {
  const [bgColor, setbgColor] = useState([
    "bg-transparent absolute lg:text-white",
    "bg-white",
  ]);

  return (
    <>
      {/* <Navbar bgColor={bgColor} /> */}
      <Navbar2 bgColor={bgColor} />
      <div className=" min-h-[30rem]">
        <Outlet context={[setbgColor]} />
      </div>
      <Footer />
    </>
  );
};

export default Mainlayout;
