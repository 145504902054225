import React, { useEffect, useState } from "react";
import useAuth from "../../CustomHook/useAuth";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

const Navbar2 = ({ bgColor }) => {
  const navItem = [
    { title: "college" },
    { title: "Exams" },
    { title: "Courses" },
    { title: "Study Aboard" },
    { title: "latest Updates" },
    { title: "More" },
  ];

  

  const [screenWidth, setscreenWidth] = useState(getscreenWidth());
  const [auth, setauth] = useState(false);

  function verifyauth(value) {
    setauth(value);
  }

  function getscreenWidth() {
    return window.innerWidth;
  }
  useEffect(() => {
    function handleResize() {
      setscreenWidth(getscreenWidth());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* DESKTOP NAVBAR */}
      {screenWidth > 1022 && (
        <div
          className={`hidden lg:block  ${bgColor[0]} w-full top-0 transition-all duration-200 z-10 ease-in-out`}
        >
          <DesktopNavbar
            navItem={navItem}
            auth={auth}
            verifyauth={verifyauth}
          />
        </div>
      )}

      {/* MOBILE NAVBAR */}
      {screenWidth <= 1022 && (
        <div className={`block lg:hidden w-full ${bgColor[0]} z-10 `}>
          <MobileNavbar navItem={navItem} bgColor={bgColor} />
        </div>
      )}
    </>
  );
};

export default Navbar2;
