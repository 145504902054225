import React from "react";
import Tabs from "../../utilites/Tabs";

export default function Finder({setfilterClg}) {
  return (
    <>
      <section className="bg-white py-16 text-center">
        <div className="max-w-6xl w-full mx-auto">
          <h2 className="lg:px-16 p-0 mb-4 font-bold lg:text-4xl text-[#C00404] text-2xl">BookMyCourse</h2>
          <h3 className="lg:px-16 p-0  lg:text-3xl text-sm mb-6"> Explore <span className="text-[#C00404] font-bold">25000+</span>  Colleges, <span className="text-[#C00404] font-bold">200+</span>  Exams & More</h3>
          <p className="lg:block hidden text-[#999] mb-12">Reset your research strategy by browsing through our lists of top colleges, exams, courses and careers based on your area of interest!</p>
          <div className="w-full lg:p-0 p-2 ">
            <Tabs setfilterClg={setfilterClg}/>
          </div>
        </div>
      </section>

    </>
  );
}
