import React from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom';
import NoData from '../NoData';

const Courses = () => {
    let navigate = useNavigate();
    //eslint-disable-next-line
    const [clgData, placementData, coursesData] = useOutletContext();


    return (
        <>

            <div className='font-semibold  text-center'>
                <h2>Courses  List</h2>
                <h1 className='text-xl'>{clgData.college_data.college_name} </h1>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-5 gap-3'>
                {/* SIDEBAR SECTION OF COURSES */}
                <div className=' border shadow-xl rounded-md pb-2 min-h-[25rem] hidden  bg-white lg:flex flex-col'>
                    <h1 className='font-semibold  text-lg py-2 text-center bg-gray-50'>Programs</h1>
                    {coursesData?.stream_Data.map((item, i) => (
                        <a href={`#${item.stream_name}`} className=' border-t border-b border-gray-400 p-2 cursor-pointer'>{item.stream_name}</a>
                    ))}
                </div>
                <div className='col-span-4  p-2 bg-gray-50 rounded-md shadow-xl'>
                    <h1 className='text-lg font-semibold'>{clgData.college_data.college_name} </h1>
                    {coursesData === null && <NoData />}

                    {/* TABLE SECTION OF COURSES */}
                    <table className='w-full text-center border bg-white shadow-lg'>
                        <thead>
                            <tr>
                                <th className='bg-gray-100 rounded-md p-2'>COURSES</th>
                                <th className='bg-gray-100 rounded-md p-2'>TOTAL FEES</th>
                                <th className='bg-gray-100 rounded-md p-2'>ELIGIBLITY</th>
                            </tr>
                        </thead>
                        <tbody >
                            {coursesData?.courses.map((item, i) => (
                                <tr key={i} className='border' >
                                    <td className='p-2'>{item.name}</td>
                                    <td className='p-2'>{item.price}</td>
                                    <td className='p-2'>{item.intake}</td>
                                </tr>
                            ))}


                        </tbody>
                    </table>

                    {/* CARD SECTION OF COURSES */}
                    {coursesData?.stream_Data.map((item, i) => (
                        <div id={`${item.stream_name}`} key={i} className='my-8 bg-white shadow-lg rounded-md p-4'>
                            <h1 className='font-semibold text-lg'>{item.stream_name}</h1>
                            <div className='flex my-4 space-x-10 font-medium'>
                                <p>Duration:{item.duration} Years</p>
                                <p>Fees: INR {item.fees} (Annually)</p>
                            </div>
                            <p className='font-medium'>Study Mode: {item.study_mode}</p>
                            <p>Available Courses</p>
                            <div className=' border w-full max-h-[20rem] overflow-auto p-3 space-y-4'>
                                {/* SUB CARD OF CARDS IN COURSES */}
                                {coursesData?.courses.map((subitem, index) => (
                                    <div key={index} className='border p-2 rounded-md text-sm lg:text-base space-y-5'>
                                        <div className='flex justify-between '>
                                            <p>{subitem.name}</p>
                                            <button onClick={()=>{ navigate('/apphome')}} className='border border-red-500 text-red-500 p-2 rounded-md  whitespace-nowrap hover:bg-red-500 hover:text-white'>Apply now</button>
                                        </div>
                                        <div className='flex space-x-10'>
                                            <p>Fees: INR {subitem.price}</p>
                                            <p>Duration:{subitem.intake} years</p>
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>
                    ))}


                </div>

            </div>

        </>
    )
}

export default Courses