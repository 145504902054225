import React from 'react'

const Aboutus = (props) => {


    // const iframe=`<iframe class=" w-[inherit] h-[inherit] border border-red-500  ml-[15px]"
    // src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15047.713256568513!2d72.81609734999999!3d19.4586573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1649049175124!5m2!1sen!2sin"
    // width="900" height="800" style="border:0;" allowFullScreen="" loading="lazy"
    // referrerPolicy="no-referrer-when-downgrade"></iframe>`;

    const iframe = `<iframe className=" w-[488px] h-[inherit]  ml-[15px]"
    src="https://maps.google.com/maps?q=${props?.data?.latitude},${props?.data?.longitude} &z=15&output=embed"
    width=${props.width}
    height=${props.height} frameborder="0" style="border:0"></iframe>`
    return (
        <div className={` grid grid-cols-1 lg:grid-cols-5 min-h-[30rem] w-full overflow-auto  mb-2 ${props.fontSize}`}>
            <div className='col-span-2 py-4 px-3 space-y-7'>
                <h1 className='font-bold '>{props?.data?.college_name}, Contact Number, Address & Location Map</h1>
                <div className='grid grid-cols-3 gap-5 '>
                    <div className='text-gray-400 text-end space-y-6'>
                        <p>Contact Us:</p>
                        <p>Email Id:</p>
                        <p>Website:</p>
                        <p>Address:</p>
                    </div>
                    <div className='font-semibold col-span-2 space-y-6'>
                        <p>+91 {props?.data?.contact_no}</p>
                        <p>{props?.data?.email_id}</p>
                        <p>{props?.data?.website}</p>
                        <p>{props?.data?.address}</p>
                    </div>

                </div>
            </div>
            <div className=' col-span-3 mx-auto overflow-auto'>
                <div className='w-full h-full' dangerouslySetInnerHTML={{ __html: iframe ? iframe : "" }} />
            </div>
        </div>
    )
}

export default Aboutus