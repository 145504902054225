import React, { useEffect, useState } from 'react'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { GET } from '../../axiosHelper/Helper';
import { failMsg } from '../../utilites/Notifcation';
import NoData from '../NoData';

const NewsDetail = () => {
  const [newstab, setnewstab] = useState(1);
  const [newsCategory, setnewsCategory] = useState(null);
  const [newsData, setnewsData] = useState(null);
  const { REACT_APP_IMGBASEURL } = process.env;
  const loadNewsByCategory = (id) => {

    GET(`user/newscategory/${id}`)
      .then(res => {
        setnewsData(res.serverData?.news_list)
      })
      .catch(err => {
        failMsg(err);
      })


  }

  const loadNewsCategory = () => {
    GET(`user/newscategory`)
      .then(res => {
        setnewsCategory(res.serverData.news_category_list);
        loadNewsByCategory(res.serverData.news_category_list[0].id);
      })
      .catch(err => {
        failMsg(err);
      })
  }

  const handleClick = (i, id) => {
    setnewstab(i);
    loadNewsByCategory(id);

  }
  useEffect(() => {

    loadNewsCategory();

  }, [])

  return (
    <div>
      <h1 className='font-semibold text-xl'>News Category</h1>
      {newsCategory === null && <NoData />}
      {newsCategory !== null && <>
        <div className=' border-b border-red-500 flex justify-end gap-2 font-medium'>
          {newsCategory.map((item, i) => (
            <div onClick={() => { handleClick((i + 1), item.id) }} className={`${newstab === i + 1 ? 'bg-red-500 text-white' : 'rounded-t-md p-2'} rounded-t-md p-2 cursor-pointer`}>{item.category}</div>
          ))}

        </div>
        <div className='p-3 h-[30rem] overflow-auto space-y-4'>
          {/* NEWS DETAILS CARDS */}
          {newsData !== null ?
            newsData.map((item, i) => (
              <div key={i} className='border shadow-lg p-2 grid grid-cols-1 lg:grid-cols-6 lg:gap-2 rounded-md  w-full'>
                <img className='col-span-2 h-60 w-full' src={`${REACT_APP_IMGBASEURL}${item.image}`} alt='news img' />

                <div className=' col-span-4'>
                  <h2 className='font-medium text-lg'>{item.headlines}</h2>
                  <div className='flex gap-5'>
                    <p>By: {item.author}</p>
                    <p className='flex items-baseline gap-2'><FaRegCalendarAlt /> {item.published_date}</p>
                  </div>
                  <div className=' h-24  py-2 overflow-auto'>
                    <p dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>
                </div>


              </div>
            ))
            : <NoData />}


        </div>
      </>}
    </div>
  )
}

export default NewsDetail