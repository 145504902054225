import React, { useEffect, useState } from "react";
import Allicons from "../assets/images/Allicons.webp";
import { Link } from "react-router-dom";
import { GET } from "../axiosHelper/Helper";
import { failMsg, removeWhitespace } from "./Notifcation";
import Spinner from "../components/Spinner";
import NoData from "../components/NoData";

export default function Tabs({ setfilterClg }) {
  const [openTab, setOpenTab] = useState(1);
  const [isLoading, setisLoading] = useState(true);
  const [streamData, setstreamData] = useState([]);
  const [courseData, setcourseData] = useState([]);
  const loadStream = () => {
    GET("user/stream")
      // axios.get(`${REACT_APP_BASEURL}user/stream`)
      .then((res) => {
        // console.log(res.data.serverData);
        setstreamData(res.serverData);
      })
      .catch((err) => {
        failMsg(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  const loadCourse = () => {
    GET("user/course")
      // axios.get(`${REACT_APP_BASEURL}user/course`)
      .then((res) => {
        // console.log(res.data.serverData);
        setcourseData(res.serverData);
      })
      .catch((err) => {
        failMsg(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    loadStream();
    loadCourse();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="bg-[#f7f7f7] border-1 border-[#e8e8e8] text-lg rounded-3xl w-fit mx-auto mb-6">
            <ul
              className="flex mb-0 list-none flex-wrap pt-1 pb-1 flex-row cursor-pointer"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <span
                  className={
                    "text-xs rounded-3xl font-bold uppercase px-3 py-2    block leading-normal " +
                    (openTab === 1
                      ? "text-white shadow-lg bg-red-600"
                      : "text-red-600 ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  streams
                </span>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <span
                  className={
                    "text-xs font-bold uppercase px-3 py-2  rounded-3xl block leading-normal " +
                    (openTab === 2
                      ? "text-white shadow-lg bg-red-600"
                      : "text-red-600 ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  Courses
                </span>
              </li>
            </ul>
          </div>

          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded">
            <div className="px-3 py-5 flex-auto">
              <div className="tab-content tab-space ">
                {!isLoading && (
                  <>
                    <div
                      className={openTab === 1 ? "block" : "hidden"}
                      id="link1"
                    >
                      <div className="lg:table block  w-auto overflow-x-auto lg:clear-none  clear-both lg:w-full">
                        <ul className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
                          {streamData?.length > 0 ? (
                            streamData?.map((item, index) => (
                              <li
                                onClick={() => {
                                  setfilterClg(["stream", item.id]);
                                }}
                                key={index}
                                className="table-cell w-full  px-1 "
                              >
                                <Link
                                  to={`/collegelist/${removeWhitespace(
                                    item.name
                                  )}`}
                                  className="text-[#333] group-hover:text-[#348fd6]"
                                >
                                  <div
                                    className="group cursor-pointer h-36 mb-2 py-5  text-center rounded-sm bg-white overflow-hidden transition-all duration-300 px-4 hover:shadow-md hover:scale-110"
                                    style={{ border: "1px solid #e8e8e8" }}
                                  >
                                    <div
                                      className="block mx-auto mb-3 w-10 h-10 bg-no-repeat grayscale group-hover:grayscale-0"
                                      style={{
                                        background: `url(${Allicons})`,
                                        backgroundPosition: "-19px -234px",
                                      }}
                                    ></div>
                                    <h6>{item.name}</h6>
                                  </div>
                                </Link>
                              </li>
                            ))
                          ) : (
                            <NoData className={"col-span-4"} />
                          )}
                        </ul>
                      </div>
                    </div>
                    <div
                      className={openTab === 2 ? "block" : "hidden"}
                      id="link2"
                    >
                      <div className="lg:table block  w-auto overflow-x-auto lg:clear-none  clear-both lg:w-full">
                        <ul className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
                          {courseData?.length > 0 ? (
                            courseData?.map((item, index) => (
                              <li
                                onClick={() => {
                                  setfilterClg(["degree", item.id]);
                                }}
                                key={index}
                                className="table-cell w-full  px-1 "
                              >
                                <Link
                                  to={`/collegelist/${removeWhitespace(
                                    item.name
                                  )}`}
                                  className="text-[#333] group-hover:text-[#348fd6]"
                                >
                                  <div
                                    className="group cursor-pointer h-36 mb-2 py-5  text-center rounded-sm bg-white overflow-hidden transition-all duration-300 px-4 hover:shadow-md hover:scale-110"
                                    style={{ border: "1px solid #e8e8e8" }}
                                  >
                                    <div
                                      className="block mx-auto mb-3 w-10 h-10 bg-no-repeat grayscale group-hover:grayscale-0"
                                      style={{
                                        background: `url(${Allicons})`,
                                        backgroundPosition: "-19px -234px",
                                      }}
                                    ></div>
                                    <h6>{item.name}</h6>
                                  </div>
                                </Link>
                              </li>
                            ))
                          ) : (
                            <NoData className={"col-span-4"} />
                          )}
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                <Spinner isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
