import React, { useEffect, useState } from "react";
import Modal from "../../modal/Modal";
import Login from "../../Login/Login";
import OtpVerify from "../../Login/OtpVerify";
import Register from "../../Login/Register";
import { LogOutModal } from "../../../utilites/Notifcation";
import useAuth from "../../../CustomHook/useAuth";

const LoginBtn = ({ verifyauth }) => {
  const [isOpen, setisOpen] = useState(false);

  const { userName, logOut } = useAuth();

  const [currentForm, setcurrentForm] = useState(1);

  const handleCurrentForm = (page) => {
    setcurrentForm(page);
  };

  const handleModalClose = () => {
    setisOpen(false);
  };

  return (
    <>
      <button
        onClick={() => {
          if (userName !== null) {
            LogOutModal(() => {
              logOut();
            });
          } else {
            setisOpen(true);
            handleCurrentForm(0);
          }
        }}
        className="bg-red-400  w-fit  p-2 rounded-md text-white    hover:bg-red-500"
      >
        {userName === null ? "Login/Signup" : userName}
      </button>

      {/* LOGIN MODAL */}
      <Modal isOpen={isOpen} setisOpen={setisOpen}>
        <div className="flex flex-col  gap-3 justify-center items-center lg:w-1/2 w-[95%] pb-5">
          {/* LOGIN FORM */}
          {currentForm === 0 && (
            <Login
              handleCurrentForm={handleCurrentForm}
              handleModalClose={handleModalClose}
              verifyauth={verifyauth}
            />
          )}

          {/* LOGIN VIA OTP FORM */}
          {currentForm === 1 && (
            <OtpVerify handleCurrentForm={handleCurrentForm} />
          )}

          {/* REGISTER  FORM */}
          {currentForm === 2 && (
            <Register handleCurrentForm={handleCurrentForm} />
          )}
        </div>
      </Modal>
    </>
  );
};

export default LoginBtn;
