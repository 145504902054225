import React from 'react'

const RadioFields = ({ type, label, name, value, onChange }) => {
  return (
    <div>
      <label className=' flex gap-3 items-center p-3'>
        <input type={type} id={name} name={name} value={value} onChange={onChange} className='w-4 h-4' />
        <p>{label}</p>
      </label>
    </div>
  )
}

export default RadioFields