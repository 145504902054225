import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import college from '../../../assets/images/college.avif'

const NewsSlider = ({all_news}) => {
    const { REACT_APP_IMGBASEURL } = process.env;
    return (
        <div className='bg-white shadow-xl rounded-md px-10 pt-5 pb-3 space-y-3'>
            <h1 className='font-semibold text-lg capitalize'>Related News</h1>

            <Swiper breakpoints={{
                576: {
                    // width: 576,
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                768: {
                    // width: 768,
                    slidesPerView: 3,
                    spaceBetween: 30
                },
            }} navigation={true} modules={[Navigation]} className=" w-full h-fit">
                {
                   all_news.map((item, i) => (
                    <SwiperSlide className='text-center rounded-md border shadow-lg'>
                        <img className='object-cover' src={`${REACT_APP_IMGBASEURL}${item.image}`} alt='news img' />
                        <div className='mt-3'>
                            <h1 className='font-semibold'>{item.headlines}</h1>
                            <p>By:{item.author}</p>
                            <p>Updated On:{item.published_date}</p>
                        </div>
                    </SwiperSlide>
                ))
                }


            </Swiper>

        </div >
    )
}

export default NewsSlider