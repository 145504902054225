import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import image1 from "../assets/images/image1.jpg";
import { changeKeyNameOfObject, failMsg } from "../utilites/Notifcation";
import { useCart } from "react-use-cart";
import { GET } from "../axiosHelper/Helper";
import Spinner from "../components/Spinner";

const ApplicationHome = () => {
  const { REACT_APP_IMGBASEURL } = process.env;
  const { REACT_APP_BASEURL } = process.env;
  const [isLoading, setisLoading] = useState(true);
  const { addItem, removeItem } = useCart();
  const [setbgColor] = useOutletContext();
  const [buttonstate, setbuttonstate] = useState([]);

  const [Data, setData] = useState([]);
  const [newdata, setnewdata] = useState([]);

  //FUNCTION FOR THE BUTTON START OF ADD TO CART
  const buttonStateForCart = (item) => {
    let cartData;

    const value = [...buttonstate];
    value[item.id] = !buttonstate[item.id];
    setbuttonstate(value);
    localStorage.setItem("buttonState", JSON.stringify(value));

    if (value[item.id]) {
      // axios.get(`${REACT_APP_BASEURL}user/course/${item.id}`)
      GET(`${REACT_APP_BASEURL}user/course/${item.id}`)
        .then((res) => {
          // let cartData = Object.assign(item, res.data.serverData)
          if (res.status_code === 200) {
            cartData = { ...item, courses: res.serverData };
            addItem(cartData);
          }
        })
        .catch((err) => {
          failMsg(err);
        });
    } else {
      removeItem(item.id);
    }
  };

  //FUNCTION TO LOAD THE COLLEGES
  const loadClg = () => {
    // axios.get(`${REACT_APP_BASEURL}user/college/popular`)
    GET("user/college/popular")
      .then((res) => {
        setData(res.serverData);
      })
      .catch((err) => {
        failMsg(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    setnewdata(changeKeyNameOfObject(Data));
  }, [Data]);

  useEffect(() => {
    const buttonValue = JSON.parse(localStorage.getItem("buttonState"));
    setbgColor(["bg-white sticky shadow-xl", "bg-black"]);
    window.scrollTo(0, 0);
    loadClg();
    if (buttonValue) {
      setbuttonstate(buttonValue);
    }

    // addItem({'id':1,'price':200,'name':'dummy'})
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container mx-auto">
      {/* SECTION FOR THE SLIDER  */}
      <div className="p-5">
        <Swiper
          loop={true}
          loopFillGroupWithBlank={true}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img className="w-full" src={image1} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-full" src={image1} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="w-full" src={image1} alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
      {/* SECTION FOR THE POPULAR SECTION */}
      <div>
        <h1 className=" text-center font-semibold text-2xl">Popular College</h1>
        {/* CARDS OF THE POPULAR SECTION */}
        <div className="p-5 grid grid-cols-2 lg:grid-cols-6 gap-4">
          {newdata?.length > 0 ? (
            newdata?.map((item, i) => (
              <div
                key={i}
                className="flex flex-col items-center w-full bg-white shadow-xl border rounded-md p-2 space-y-5"
              >
                <img
                  className="w-fit h-40 rounded-md object-cover"
                  src={`${REACT_APP_IMGBASEURL}${item.logo}`}
                  alt="College img"
                />
                <div className="space-y-5">
                  <span className="flex gap-2">
                    Price:
                    <p className="text-red-500 ">{`Rs: ${item.price}`}</p>
                  </span>
                  <button
                    onClick={() => {
                      buttonStateForCart(item, i);
                    }}
                    className={`capitalize transition-all duration-500 ${
                      buttonstate[item.id] ? "bg-gray-500" : "bg-red-500"
                    } w-40 py-2 px-5 rounded-md text-white ${
                      buttonstate[item.id]
                        ? "hover:bg-gray-600"
                        : "hover:bg-red-600"
                    }`}
                  >
                    {buttonstate[item.id] ? "Remove" : "Add to cart"}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className=" col-span-6 flex justify-center text-xl font-semibold">
              No Data Available
            </div>
          )}
          <Spinner isLoading={isLoading} className={"col-span-6"} />
        </div>
      </div>
    </div>
  );
};

export default ApplicationHome;
