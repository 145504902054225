import React, { useRef } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { FaUniversity, FaUserCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import CartBtn from "./common/CartBtn";
import ContactHeader from "./common/ContactHeader";
import LoginBtn from "./common/LoginBtn";
import Logo from "./common/Logo";
import SearchBtn from "./common/SearchBtn";

const MobileNavbar = ({ navItem, bgColor }) => {
  const sidebar = useRef();

  const toggleSidebar = () => {
    sidebar.current.classList.toggle("sidebar");
  };

  return (
    <div className={`w-full flex items-center justify-between  px-5 h-20 `}>
      <Logo toggleSidebar={toggleSidebar} bgColor={bgColor} />

      {/* SIDEBAR */}
      <div
        ref={sidebar}
        className=" -translate-x-[100%]  transition-all duration-200 fixed top-0 left-0 w-10/12 h-screen overflow-y-auto  bg-white  shadow-xl"
      >
        {/* CLOSE ICON */}
        <IoClose
          onClick={toggleSidebar}
          className="absolute right-1 text-white text-3xl top-3 lg:hidden"
        />
        {/* PROFILE SECTION */}
        <div className="bg-gray-800 w-full p-5">
          <FaUserCircle className="text-white w-28 h-20 mb-3" />
          <LoginBtn />
        </div>

        {/* MAPPING NAVIGATION SECTION */}
        <ul className="capitalize flex flex-col justify-center gap-3 border  border-gray-300">
          {navItem?.map((item, i) => (
            <li
              key={i}
              className="flex  items-center justify-between gap-1 p-3 cursor-pointer"
            >
              <span className="flex gap-3 items-center">
                <FaUniversity className="text-gray-400" />
                {item.title}
              </span>
              <AiOutlineRight className="" />
            </li>
          ))}
        </ul>

        {/* CONTACT SECTION */}
        <ContactHeader
          containerStyle={"flex-col gap-2 px-5 pt-4"}
          iconStyle={"flex-col"}
        />
      </div>

      {/* ICON SECTION OF NAVBAR */}
      <div className="flex items-center gap-3">
        <SearchBtn />

        {/* CART SECTION */}
        <CartBtn />
      </div>
    </div>
  );
};

export default MobileNavbar;
