import axios from "axios";
import axiosRetry from "axios-retry";

const { REACT_APP_BASEURL } = process.env;

const axiosInst = axios.create({
  baseURL: REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
  },
});

//sending credentials http only cookies stored in browser
// axiosInst.defaults.withCredentials = true;

// Intercepts each API request and adds Auth header
axiosInst.interceptors.request.use(async (request) => {
  const jwtToken = sessionStorage.getItem("token");
  if (jwtToken) {
    request.headers["Authorization"] = `Bearer ${jwtToken}`;
  }
  return request;
});
axiosRetry(axiosInst, { retries: 3 });

async function callApi(url, method, data, headers) {
  try {
    // console.log(data);
    const options = {
      method,
      url,
      data: data,
      headers: headers,
      mode: "cors",
    };

    const response = await axiosInst(options);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error Details url, method, headers, body, error", {
      url,
      method,
      data,
      headers,
      error,
    });
    throw error;
  }
}

export const GET = (path, headers = {}) => callApi(path, "GET", headers);
export const POST = (path, data, headers = {}) =>
  callApi(path, "POST", data, headers);
export const PUT = (path, body, headers = {}) =>
  callApi(path, "PUT", body, headers);
export const DELETE = (path, body, headers = {}) =>
  callApi(path, "DELETE", body, headers);

export default axiosInst;
