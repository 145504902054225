import React from "react";
import PopularSlider from "../../utilites/PopularSlider";

export default function Popular() {
  return (
    <section className="py-12 text-center bg-[#fff]  h-full">
      <div className="max-w-6xl w-full mx-auto p-2 lg:p-0">
        <h2 className="lg:px-16 px-0 font-bold lg:text-3xl text-2xl mb-3 lg:mb-12 text-[#c00404]">Popular College</h2>
        <div>
          <PopularSlider />
        </div>
      </div>
    </section>
  );
}
