import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png";

const Logo = ({ bgColor, toggleSidebar = null }) => {

  return (
    <div className="flex gap-2">
      <div
        onClick={() => {
          // eslint-disable-next-line no-unused-expressions
          toggleSidebar !== null && toggleSidebar();
        }}
        className="ham_menu_box "
      >
        <div className={`ham_menu ${bgColor ? bgColor[1] : ""}`}></div>
      </div>

      <Link to="/" className="logo ">
        <img className="w-28 h-12" src={logo} alt="" />
      </Link>
    </div>
  );
};

export default Logo;
