import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import profileImg from "../assets/images/Allicons.webp";

export default function MediaSlider() {
  return (
    <Swiper
    slidesPerView={4}
    spaceBetween={30}
    slidesPerGroup={1}
      loop={true}
      loopFillGroupWithBlank={true}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        300: {
          slidesPerGroup : 1,
          slidesPerView: 1,
          spaceBetween: 10,
        },
        640: {
          slidesPerGroup : 1,
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerGroup : 2,
          slidesPerView: 2,
          spaceBetween: 10,
        },
        1024: {
          slidesPerGroup : 5,
          slidesPerView: 5,
          spaceBetween: 50,
        },
      }}
 
 
      modules={[ Autoplay]}
      className="mySwiper"
    >
      <SwiperSlide>
            <div className="shadow-none w-fit mx-auto bg-transparent py-12 h-full text-left rounded-lg transition-all">
                <div>
                    <div className="w-[200px] h-[100px] inline-block mb-1 overflow-hidden bg-no-repeat" style={{backgroundImage : `url(${profileImg})` , backgroundPosition : '-19px -1068px'}}></div>
                </div>
            </div>
      </SwiperSlide>
      <SwiperSlide>
            <div className="shadow-none w-fit mx-auto bg-transparent py-12 h-full text-left rounded-lg transition-all">
                <div>
                    <div className="w-[200px] h-[100px] inline-block mb-1 overflow-hidden bg-no-repeat" style={{backgroundImage : `url(${profileImg})` , backgroundPosition : '-19px -811px'}}></div>
                </div>
            </div>
      </SwiperSlide>
      <SwiperSlide>
            <div className="shadow-none w-fit mx-auto bg-transparent py-12 h-full text-left rounded-lg transition-all">
                <div>
                    <div className="w-[200px] h-[100px] inline-block mb-1 overflow-hidden bg-no-repeat" style={{backgroundImage : `url(${profileImg})` , backgroundPosition : '-236px -1068px'}}></div>
                </div>
            </div>
      </SwiperSlide>
      <SwiperSlide>
            <div className="shadow-none w-fit mx-auto bg-transparent py-12 h-full text-left rounded-lg transition-all">
                <div>
                    <div className="w-[200px] h-[100px] inline-block mb-1 overflow-hidden bg-no-repeat" style={{backgroundImage : `url(${profileImg})` , backgroundPosition : '-19px -937px'}}></div>
                </div>
            </div>
      </SwiperSlide>
      <SwiperSlide>
            <div className="shadow-none w-fit mx-auto bg-transparent py-12 h-full text-left rounded-lg transition-all">
                <div>
                    <div className="w-[200px] h-[100px] inline-block mb-1 overflow-hidden bg-no-repeat" style={{backgroundImage : `url(${profileImg})` , backgroundPosition : '-19px -811px'}}></div>
                </div>
            </div>
      </SwiperSlide>
        </Swiper>
  );
}
