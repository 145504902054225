import React, { useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import NoData from '../NoData';
import NewsSlider from './common/NewsSlider';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

const Gallery = () => {
    const { REACT_APP_IMGBASEURL } = process.env;
    const [{ all_news, college_gallery, }] = useOutletContext();
    const [modal, setmodal] = useState(false);
    return (
        <>
            {/* `${REACT_APP_IMGBASEURL}${item.image_name}` */}
            <div className='grid grid-cols-2 lg:grid-cols-5 bg-white rounded-md shadow-lg gap-3 p-3'>
                {college_gallery.map((item, i) => (
                    <img key={i} onClick={() => { setmodal(true) }} className='w-60 h-44 rounded-md cursor-pointer' src={`https://images.unsplash.com/photo-1658496594141-589b0ec66b8e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=724&q=80`} alt='img' />
                ))}

            </div>

            {/* SECTION FOR THE  RELATED NEWS */}
            {all_news !== null ?
                <NewsSlider all_news={all_news} />
                : <NoData />}
            {/* <button className='bg-red-500 text-white ' onClick={() => { setmodal(false) }}>close</button> */}

            {modal && (
                <div className='fixed top-0 left-0 bg-light-black z-10 w-full h-full mt-0 -translate-y-6 flex justify-center items-center '>
                    <div className='bg-white w-[60%] h-fit rounded-md p-5'>
                        <div className='p-2 flex justify-between items-center'>
                            <h2 className='font-semibold text-3xl pl-6'>Gallery</h2>
                            <AiFillCloseCircle onClick={() => { setmodal(false) }} className='text-3xl cursor-pointer' />
                        </div>
                        <Swiper breakpoints={{
                            576: {
                                // width: 576,
                                slidesPerView: 2,
                                spaceBetween: 30
                            },
                            768: {
                                // width: 768,
                                slidesPerView: 3,
                                spaceBetween: 30
                            },
                        }} navigation={true} modules={[Navigation]} className=" w-full h-fit">
                            {
                                [...Array(5)].map((_, i) => (
                                    <SwiperSlide className='text-center rounded-md border shadow-lg'>
                                        <img className='' src={`https://images.unsplash.com/photo-1658496594141-589b0ec66b8e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=724&q=80`} alt='news img' />
                                    </SwiperSlide>
                                ))
                            }


                        </Swiper>
                    </div>

                </div>)
            }

        </>

    )
}

export default Gallery


// <div className='bg-white shadow-xl rounded-md px-10 pt-5 pb-3 space-y-3'>
//                 <h1 className='font-semibold text-lg capitalize'>Image Gallery</h1>

//

//             </div >