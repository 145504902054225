import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { Link } from "react-router-dom";
import { GET } from "../axiosHelper/Helper";
import { failMsg } from "./Notifcation";
import Spinner from "../components/Spinner";
import NoData from "../components/NoData";

export default function PopularSlider() {
  const { REACT_APP_IMGBASEURL } = process.env;
  const [Clg, setClg] = useState([])
  const [isLoading, setisLoading] = useState(true);
  const loadClg = () => {
    GET('user/college/popular')
      .then(res => {
        setClg(res.serverData)
      })
      .catch(err => {
        failMsg(err);
      })
      .finally(() => {
        setisLoading(false);
      })
  }
  useEffect(() => {
    loadClg();
    //eslint-disable-next-line
  }, [])

  return (
    <Swiper breakpoints={{
      576: {
        // width: 576,
        slidesPerView: 2,
        spaceBetween: 30
      },
      768: {
        // width: 768,
        slidesPerView: 3,
        spaceBetween: 30
      },
    }} navigation={true} autoplay={true} modules={[Navigation, Autoplay]} className=" w-full h-fit">
      {!isLoading && (Clg.length > 0 ? (
        Clg.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="h-full w-full bg-[#c00404] text-white transition-all text-left rounded-lg mb-8 " style={{ border: "1px solid #eee" }}>
              <div className="relative h-[125px] cursor-pointer overflow-hidden mb-1 after:absolute after:w-full after:h-full after:top-0 after:left-0 after:bg-[#0000001a] after:rounded-tl-lg after:rounded-tr-lg  ">
                <Link to="" className="text-center w-full text-[#348fd6] lg:text-[#fd6c73] ">
                  <img src={`${REACT_APP_IMGBASEURL}${item.logo}`} className="rounded-tl-lg rounded-tr-lg block w-full transition-all  " alt="college img" />
                </Link>
              </div>
              <div className="p-2  text-center ">
                <p className="text=[#333] mb-2 p-0  font-bold ">{item.names}</p>
              </div>
            </div>
          </SwiperSlide>
        ))

      ) : (<NoData />))}

      <Spinner isLoading={isLoading} />


    </Swiper>
  );
}
