import React from "react";

const Inputfields = ({
  label,
  type,
  value,
  name,
  onChange,
  placeholder,
  error,
  accept,
  inputstyle,
  labelstyle,
  refs,
  children,
  onKeyUp,
  required,
}) => {
  return (
    <div className="flex flex-col relative  pb-7 w-full">
      <label className={`font-medium ${labelstyle}`} htmlFor={name}>
        {label} {required ? <span className="text-red-500">*</span> : null}
      </label>
      <div className="flex items-center group">
        {children}
        <input
          ref={refs}
          accept={accept}
          required={required}
          autoComplete="off"
          className={` ${inputstyle} py-1 px-2 w-full outline-none border border-black focus:ring-2 focus:border-0 focus:ring-blue-500 `}
          type={type}
          value={value}
          name={name}
          id={name}
          onChange={onChange}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
        />
      </div>
      <p className="text-red-500 font-semibold absolute top-16">{error}</p>
    </div>
  );
};

export default Inputfields;
