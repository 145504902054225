import React from "react";
import { useNavigate } from "react-router-dom";
import Allicons from "../../assets/images/Allicons.webp";
import Btn from "../../utilites/Btn";

export default function Application() {
  const navigate=useNavigate();
  return (
    <section className="bg-white py-12 text-center">
      <div className="max-w-6xl w-full mx-auto">
        <h2 className="lg:px-16 px-0 font-bold lg:text-4xl text-2xl lg:mb-2 mb-1 text-[#c00404]">Common Application Form (CAF)</h2>
        <p className="lg:px-16 px-0 lg:font-normal font-bold  text-sm lg:text-3xl mb-7 lg:mb-6">1 single form and get access to <span className="text-[#c00404] font-bold">600+</span>  colleges across India!</p>
        <p className="lg:block hidden px-16 text-[#999] mb-12 text-sm">Use the unique interface of the CAF platform to apply to several colleges and courses using a single window application form. Waste no time, and apply to the college of your dreams now!</p>
        <ul className="flex flex-wrap mb-12">
          <li className="float-left w-1/2  lg:w-1/4 px-0 lg:px-2">
            <div className="mb-3 text-center rounded-sm bg-white p-1" style={{ borderRight: "1px solid rgba(121,131,147,0.30)" }}>
              <div className="block ">
                <div className="my-4 mx-auto w-[50px] h-[50px] bg-no-repeat" style={{ backgroundImage: `url(${Allicons})`, backgroundPosition: "-721px -144px" }}></div>
                <h5 className="font-bold text-[#253246] text-sm lg:text-base" style={{ margin: "5px 30px 8px" }}>
                  One platform to apply to 600+ colleges
                </h5>
              </div>
            </div>
          </li>
          <li className="float-left  w-1/2  lg:w-1/4 px-0 lg:px-2">
            <div className="mb-3 text-center rounded-sm bg-white p-1" style={{ borderRight: "1px solid rgba(121,131,147,0.30)" }}>
              <div className="block ">
                <div className="my-4 mx-auto w-[50px] h-[50px] bg-no-repeat" style={{ backgroundImage: `url(${Allicons})`, backgroundPosition: "-721px -144px" }}></div>
                <h5 className="font-bold text-[#253246] text-sm lg:text-base" style={{ margin: "5px 30px 8px" }}>
                  One platform to apply to 600+ colleges
                </h5>
              </div>
            </div>
          </li>
          <li className="float-left  w-1/2  lg:w-1/4 px-0 lg:px-2">
            <div className="mb-3 text-center rounded-sm bg-white p-1" style={{ borderRight: "1px solid rgba(121,131,147,0.30)" }}>
              <div className="block ">
                <div className="my-4 mx-auto w-[50px] h-[50px] bg-no-repeat" style={{ backgroundImage: `url(${Allicons})`, backgroundPosition: "-721px -144px" }}></div>
                <h5 className="font-bold text-[#253246] text-sm lg:text-base" style={{ margin: "5px 30px 8px" }}>
                  One platform to apply to 600+ colleges
                </h5>
              </div>
            </div>
          </li>
          <li className="float-left  w-1/2  lg:w-1/4 px-0 lg:px-2">
            <div className="mb-3 text-center rounded-sm bg-white p-1">
              <div className="block ">
                <div className="my-4 mx-auto w-[50px] h-[50px] bg-no-repeat" style={{ backgroundImage: `url(${Allicons})`, backgroundPosition: "-721px -144px" }}></div>
                <h5 className="font-bold text-[#253246] text-sm lg:text-base" style={{ margin: "5px 30px 8px" }}>
                  One platform to apply to 600+ colleges
                </h5>
              </div>
            </div>
          </li>
        </ul>
        <div className="lg:px-0 px-2">
          <Btn onClick={()=>{navigate('/apphome')}} text="Start your Application now" />
        </div>
      </div>
    </section>
  );
}
