import React from "react";

const Button = ({ onClick, label, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="submit"
      className="w-full bg-custgreen text-white p-2 rounded-md shadow-lg mt-5 capitalize"
    >
      {label}
    </button>
  );
};

export default Button;
