import React, { useEffect, useRef, useState } from "react";
import { NavLink, Outlet, useOutletContext, useParams } from "react-router-dom";
import { IoLocationSharp } from "react-icons/io5";
import { GET } from "../axiosHelper/Helper";
import { failMsg } from "../utilites/Notifcation";
import Spinner from "../components/Spinner";
import NoData from "../components/NoData";

const CollegeDetails = () => {
  const [setbgColor] = useOutletContext();
  const { id } = useParams();
 
  const { REACT_APP_IMGBASEURL } = process.env;
  const [isLoading, setisLoading] = useState(true);
  const [clgData, setclgData] = useState(null);
  const [placementData, setplacementData] = useState(null);
  const [coursesData, setcoursesData] = useState(null);

  const loadClg = () => {
    GET(`user/college/${id}`)
      .then((res) => {
        setclgData(res.serverData);
      })
      .catch((err) => {
        failMsg(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const loadPlacementData = () => {
    GET(`user/placement/${id}`)
      .then((res) => {
        setplacementData(res.serverData.placement_data);
      })
      .catch((err) => {
        failMsg(err);
      });
  };

  const loadCourses = () => {
    GET(`user/courseincollege/${id}`)
      .then((res) => {
        setcoursesData(res.serverData);
      })
      .catch((err) => {
        failMsg(err);
      });
  };

  useEffect(() => {
    setbgColor(["bg-white sticky shadow-xl", "bg-black"]);
    window.scrollTo(0, 0);

    loadClg();
    loadPlacementData();
    loadCourses();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-gray-100 min-h-[30rem]">
      <div className="container lg:px-32 mx-auto py-10 px-3">
        {!isLoading &&
          (clgData !== null ? (
            <div className="space-y-6">
              {/* HEADING SECTION OF COLLEGE DETAILS PAGE */}
              <div className="flex gap-3 items-center">
                <img
                  className="w-28 h-28  object-cover"
                  src={`${REACT_APP_IMGBASEURL}${clgData.college_data?.college_logo}`}
                  alt="college logo"
                />
                <div>
                  <h1 className="font-semibold text-xl">
                    {clgData.college_data?.college_name}
                  </h1>
                  <span className="flex items-center lg:gap-3 flex-col lg:flex-row text-gray-400">
                    <p className=" flex items-center">
                      <IoLocationSharp /> {clgData.college_data?.city_name} (
                      {clgData.college_data?.state_name})
                    </p>
                    <p> Approved By: {clgData.college_data?.approved_by}</p>
                  </span>
                </div>
              </div>
              {/* NAVIGATION SECTION OF COLLEGE DETAILS PAGE */}
              <div className="bg-white uppercase p-1 grid grid-cols-3 lg:grid-cols-6 text-red-500 font-semibold rounded-2xl border text-center">
                <NavLink
                  to={`overview/${id}`}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-red-500 text-white shadow-xl rounded-2xl  p-2"
                      : "  p-2"
                  }
                >
                  Overview
                </NavLink>
                <NavLink
                  to={`courses/${id}`}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-red-500 text-white shadow-xl rounded-2xl  p-2"
                      : "p-2"
                  }
                >
                  Courses
                </NavLink>
                <NavLink
                  to={`campus/${id}`}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-red-500 text-white shadow-xl rounded-2xl  p-2"
                      : "p-2"
                  }
                >
                  Campus
                </NavLink>
                <NavLink
                  to={`placement/${id}`}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-red-500 text-white shadow-xl rounded-2xl  p-2"
                      : "p-2"
                  }
                >
                  PLACEMENTS
                </NavLink>
                <NavLink
                  to={`newdetail/${id}`}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-red-500 text-white shadow-xl rounded-2xl  p-2"
                      : "p-2"
                  }
                >
                  NEWS
                </NavLink>
                <NavLink
                  to={`gallery/${id}`}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-red-500 text-white shadow-xl rounded-2xl  p-2"
                      : "p-2"
                  }
                >
                  PICTURES
                </NavLink>
              </div>
              <Outlet context={[clgData, placementData, coursesData]} />
            </div>
          ) : (
            <NoData />
          ))}
        <Spinner isLoading={isLoading} className={"col-span-3"} />
      </div>
    </div>
  );
};

export default CollegeDetails;
