import React from "react";

const Dropdown = ({
  label,
  value,
  name,
  onChange,
  error,
  children,
  required,
}) => {
  return (
    <div className="flex flex-col relative w-full  pb-7">
      <label className="font-semibold" htmlFor={name}>
        {label}
        {required ? <span className="text-red-500">*</span> : null}
      </label>
      <select
        className="p-1 border border-black rounded-md focus:outline-blue-500 "
        required={required}
        name={name}
        value={value}
        onChange={onChange}
      >
        {children}
      </select>
      <p className="text-red-500 font-semibold absolute top-16">{error}</p>
    </div>
  );
};

export default Dropdown;
