// import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
// import { useState, useEffect, useRef } from "react";
// import axios from "axios";
// export const Notify = (type, message) => {
//   type(message);
// };

// export const useRunUseEffectOneTime = (url) => {
//   const effectRan = useRef(false);
//   const [data, setData] = useState(null);
//   const { REACT_APP_BASEURL } = process.env;
//   useEffect(() => {

//     if (effectRan.current === true) {
//       // GET(url)
//       //   .then(res => {
//       //     setData(res)
//       //   })
//       //   .catch(err => {
//       //     failMsg(err);
//       //   })
//       axios.get(`${REACT_APP_BASEURL}${url}`)
//         .then(res => {
//           setData(res.data)
//         })
//         .catch(err => {
//           failMsg(err);
//         })

//     }
//     return () => {
//       effectRan.current = true;
//     }
//     //eslint-disable-next-line
//   }, [url]);

//   return [data];
// };

export const successMsg = (msg) => {
  Swal.fire(`${msg}`, "", "success");
};
export const warningMsg = (msg) => {
  Swal.fire(`${msg}`, "", "warning");
};

export const failMsg = (msg) => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: `${msg}`,
  });
};

export const Modal = (funct, funcf) => {
  Swal.fire({
    title: "Do You Want To Add More Colleges ?",
    text: "",
    icon: "info",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      funct();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      funcf();
    }
  });
};

// LOGOUT MODAL
export const LogOutModal = (cb) => {
  Swal.fire({
    title: " Are you sure you want to log Out?",
    // text: "You won't be able to revert this!",
    icon: "warning",

    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Log Out",
  }).then((result) => {
    if (result.isConfirmed) {
      cb();
      Swal.fire({
        icon: "success",
        title: "You have successfully logged out!",
        timer: 1000,
      });
    }
  });
};

export const deleteMsg = (msg) => {
  Swal.fire("Deleted!", `${msg}`, "success");
};

export const getTodayDate = () => {
  let d = new Date();
  let month = d.getMonth() < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  let date = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  let today = `${d.getFullYear()}-${month}-${date}`;
  return today;
};

//THIS FUNCTION USED REVERSE THE DATE IN THIS '2022-07-12' FORMAT
export const reverseString = (str) => {
  return str.split("-").reverse().join("-");
};

//THIS FUNCTION REMOVE WHITE SPACE BETWEEN THE THE SENTENCES AND CONVERT FIRST LETTER OF WORDS IN UPPERCASE
export const removeWhitespace = (str) => {
  return str
    .trim()
    .toLowerCase()
    .split(" ")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join("");
};

// CHANGE APP_FEE NAME TO PRICE
export const changeKeyNameOfObject = (obj) => {
  // let newobj=obj;
  // delete Object.assign(newobj, { price: newobj.app_fee })['app_fee'];
  let result = obj.map(({ app_fee: price, ...rest }) => ({ price, ...rest }));
  // let result = obj.map((item) => {
  //   let finalObj = {};
  //   if (item !== null) {
  //     let { app_fee: price, ...rest } = item;
  //     finalObj = { price, ...rest };
  //   }
  //   return finalObj;
  // });

  return result;
};

export function debounce(func, timeout = 250) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const getArrayOfYears = () => {
  const now = new Date().getUTCFullYear();
  const years = Array(now - (now - 40))
    .fill("")
    .map((v, idx) => now - idx);
  return years;
};

//FUNCTION FOR THE STATE CHANGE OF ADD TO CART BUTTON
export const addToCartButtonState = (id) => {
  let buttonState = localStorage.setItem("buttonState", JSON.stringify([]));
  buttonState = JSON.parse(localStorage.getItem("buttonState"));

  const value = [...buttonState];
  value[id] = !buttonState[id];

  localStorage.setItem("buttonState", JSON.stringify(value));
};

// REGX PATTERN FOR THE VALIDATION OF INPUTFIELDS

export const emailregx = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

export const mobileregx = /^[6789][0-9]{9}$/;

// function changeName(arr){
//   let result=arr.map(item=>{
//       if(item !==null && item !== undefined){
//           return item
//       }
//   })
//   return result;
// }

// let res=[
//   {app_fee:200,name:'college1'},
//   null
//   {app_fee:300,name:'college2'},
//   ]
// console.log(changeName(res))
