export const agentValidation = (obj) => {
  const error = {};
  if (obj?.name?.length === 0) error.name = "Name is Required !";
  if (obj?.mobile_no?.length === 0) error.mobile_no = "mobile_no is Required!";
  if (obj?.email_id?.length === 0) error.email_id = "Email is Required!";
  if (obj?.age?.length === 0) error.age = "Age is Required!";
  else if (parseInt(obj?.age) <=0) error.age = "Invalid Age!";
  if (obj?.gender?.length === 0) error.gender = "Gender is Required!";
  if (obj?.aadhar?.length === 0) error.aadhar = "Aadhar No. is Required!";
  if (obj?.address?.length === 0) error.address = "Address is Required!";
  if (obj?.password?.length === 0) error.password = "Password is Required!";

  return error;
};
