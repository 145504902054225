import React from "react";
import { Link } from "react-router-dom";

export default function Trending() {
  return (
    <section className="py-12 text-center bg-[#f7f7f7]  h-full">
      <div className="max-w-6xl w-full mx-auto p-2 lg:p-0">
        <h2 className="lg:px-16 px-0 font-bold lg:text-3xl text-2xl mb-3 lg:mb-12 text-[#c00404]">Trending Exams</h2>
        <div className="py-4 px-2 text-center">
            <ul>
                <li className="inline-block pr-1 my-1 relative text-sm ">
                    <Link  to= ''    className="block text-[#333] my-1 px-4 rounded-2xl transition-all bg-white hover:bg-[#c00404] hover:text-white" style={{border : '1px solid #ccc'}}>MICAT 2022 Answer Key</Link>
                </li>
                <li className="inline-block pr-1 my-1 relative text-sm ">
                    <Link  to= ''    className="block text-[#333] my-1 px-4 rounded-2xl transition-all bg-white hover:bg-[#c00404] hover:text-white" style={{border : '1px solid #ccc'}}>GATE 2022 Paper Analysis (Available)</Link>
                </li>
                <li className="inline-block pr-1 my-1 relative text-sm ">
                    <Link  to= ''    className="block text-[#333] my-1 px-4 rounded-2xl transition-all bg-white hover:bg-[#c00404] hover:text-white" style={{border : '1px solid #ccc'}}>Important Events for NEST 2022</Link>
                </li>
                <li className="inline-block pr-1 my-1 relative text-sm ">
                    <Link  to= ''    className="block text-[#333] my-1 px-4 rounded-2xl transition-all bg-white hover:bg-[#c00404] hover:text-white" style={{border : '1px solid #ccc'}}>IIT JAM 2022 Paper Analysis</Link>
                </li>
                <li className="inline-block pr-1 my-1 relative text-sm ">
                    <Link  to= ''    className="block text-[#333] my-1 px-4 rounded-2xl transition-all bg-white hover:bg-[#c00404] hover:text-white" style={{border : '1px solid #ccc'}}>FAQs on KEAM Application Form 2022</Link>
                </li>
                <li className="inline-block pr-1 my-1 relative text-sm ">
                    <Link  to= ''    className="block text-[#333] my-1 px-4 rounded-2xl transition-all bg-white hover:bg-[#c00404] hover:text-white" style={{border : '1px solid #ccc'}}>NIMCET 2022 Syllabus (PDF Download)</Link>
                </li>
            </ul>
        </div>
      </div>
    </section>
  );
}
