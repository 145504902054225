import React from 'react'
import { IoFastFoodOutline } from 'react-icons/io5'
import { ImLab } from 'react-icons/im'
import { FaCarBattery, FaWater, FaHotel } from 'react-icons/fa'
import NoData from '../../NoData'

const Facilities = ({ campus_data }) => {
    return (
        <div className='bg-white shadow-xl rounded-md  p-10 space-y-3'>
            <h1 className='font-semibold text-lg capitalize'>Facilities</h1>
            <div className='grid grid-cols-2 lg:grid-cols-3 gap-3'>
                {campus_data === null && <NoData />}

                {campus_data?.power_backup === 1 ? <div className='border shadow-lg p-2 text-gray-400 rounded-md flex flex-col items-center justify-center'>
                    <FaCarBattery className='text-5xl' />
                    <p>Power Backup</p>
                </div> : null}
                {campus_data?.cold_water === 1 ? <div className='border shadow-lg p-2 text-gray-400 rounded-md flex flex-col items-center justify-center'>
                    <FaWater className='text-5xl' />
                    <p>Cold Water</p>
                </div> : null}

                {campus_data?.cafeteria === 1 ? <div className='border shadow-lg p-2 text-gray-400 rounded-md flex flex-col items-center justify-center'>
                    <IoFastFoodOutline className='text-5xl' />
                    <p>Cafeteria</p>
                </div> : null}

                {campus_data?.laboratories === 1 ? <div className='border shadow-lg p-2 text-gray-400 rounded-md flex flex-col items-center justify-center'>
                    <ImLab className='text-5xl' />
                    <p>Laboratories</p>
                </div> : null}

                {campus_data?.hostel === 1 ? <div className='border shadow-lg p-2 text-gray-400 rounded-md flex flex-col items-center justify-center'>
                    <FaHotel className='text-5xl' />
                    <p>Hostel</p>
                </div> : null}
            </div>


        </div>
    )
}

export default Facilities