import React, { useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";
import Aboutus from "../Homepage/Aboutus";
import Loan from "./common/Loan";
import Image from "./common/Image";
import NewsSlider from "./common/NewsSlider";
import Facilities from "./common/Facilities";
import { GET } from "../../axiosHelper/Helper";
import { failMsg } from "../../utilites/Notifcation";
import NoData from "../NoData";
import TopCollege from "./common/TopCollege";
// import { useCart } from 'react-use-cart';

const Overview = () => {
  let { id } = useParams();
  // const { addItem } = useCart();
  let navigate = useNavigate();

  let { pathname } = useLocation();
  const [
    { college_data, all_news, college_gallery, campus_data },
    placementData,
  ] = useOutletContext();
  const { REACT_APP_IMGBASEURL } = process.env;
  const [viewmore, setviewmore] = useState(false);
  const [scholarshipAccordian, setscholarshipAccordian] = useState(0);
  const [faqAccordian, setfaqAccordian] = useState(0);

  const [faqData, setfaqData] = useState(null);
  const [scholarshipData, setscholarshipData] = useState(null);
  const [facultyData, setfacultyData] = useState(null);
  const [addmissionData, setaddmissionData] = useState(null);
  const [coursesData, setcoursesData] = useState(null);
  const [topclg, settopclg] = useState(null);

  const loadFaq = () => {
    GET(`user/faq/${id}`)
      .then((res) => {
        setfaqData(res.serverData.faq_list);
      })
      .catch((err) => {
        failMsg(err);
      });
  };
  const loadScholarship = () => {
    GET(`user/scholarship/${id}`)
      .then((res) => {
        setscholarshipData(res.serverData.scholarship_list);
      })
      .catch((err) => {
        failMsg(err);
      });
  };
  const loadFaculty = () => {
    GET(`user/faculty/${id}`)
      .then((res) => {
        setfacultyData(res.serverData?.faculty_list);
      })
      .catch((err) => {
        failMsg(err);
      });
  };

  const loadAdmission = () => {
    GET(`user/admission/${id}`)
      .then((res) => {
        setaddmissionData(res.serverData.admissions_list);
      })
      .catch((err) => {
        failMsg(err);
      });
  };
  const loadCourses = () => {
    GET(`user/course/${id}`)
      .then((res) => {
        setcoursesData(res.serverData);
      })
      .catch((err) => {
        failMsg(err);
      });
  };

  const handleApplyNow = (item, college_data) => {
    // let cartData={...college_data,courses:[item]}
    // console.log(cartData);
    // addItem(cartData);
    navigate("/apphome");
  };

  const loadTopClg = () => {
    GET("user/college/popular")
      .then((res) => {
        settopclg(res.serverData);
      })
      .catch((err) => {
        failMsg(err);
      });
  };

  useEffect(() => {
    loadFaq();
    loadScholarship();
    loadFaculty();
    loadAdmission();
    loadCourses();
    loadTopClg();

    //eslint-disable-next-line
  }, []);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3">
      <div className="col-span-2 space-y-5 p-5">
        {/* SECTION FOR THE COLLEGE OVERVIEW */}
        <div className="bg-white shadow-xl rounded-md p-4 flex flex-col">
          <h1 className="font-semibold text-lg">{college_data.college_name}</h1>
          <div className={`${viewmore ? "h-fit" : "h-48"} overflow-hidden`}>
            <p className=" w-full text-justify whitespace-normal">
              <img
                className="block lg:float-left w-full lg:w-48 h-44 px-2"
                src={`${REACT_APP_IMGBASEURL}${college_data.college_logo}`}
                alt="college img"
              />
              <span className="font-semibold m-3">{`${college_data.college_name} Overview`}</span>
              {college_data.college_description}
            </p>
          </div>
          <button
            onClick={() => {
              setviewmore(!viewmore);
            }}
            className="mt-3 text-blue-500 font-semibold"
          >
            {viewmore ? "View less" : "View more"}
          </button>
        </div>

        {/* SECTION FOR THE COLLEGE COURSES */}
        <div className="bg-white shadow-xl rounded-md p-4">
          <h1 className="font-semibold text-lg">
            viva college Top Courses & Fees
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-2  gap-5">
            {/* CARDS OF THE COURSES SECTION */}
            {coursesData !== null && facultyData !== undefined ? (
              coursesData?.map((item, i) => (
                <div
                  key={i}
                  className="rounded-md shadow-lg border p-3 space-y-5"
                >
                  <span className="font-semibold">
                    {item.name}
                    <p className="text-sm text-gray-400 font-normal">
                      Course Offered
                    </p>
                  </span>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-400">
                      Annual Fee
                      <p className="font-semibold text-black">
                        &#8377; {item.price}
                      </p>
                    </span>
                    <button
                      onClick={() => {
                        handleApplyNow(item, college_data);
                      }}
                      className="text-red-500  border border-red-500 p-2 rounded-md hover:bg-red-500 hover:text-white "
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        </div>

        {/* SECTION FOR THE EDUCATION LOAN */}
        <Loan />

        {/* SECTION FOR THE ADDMISSION */}
        <div className="bg-white shadow-xl rounded-md p-10 space-y-3">
          <h1 className="font-semibold text-lg">Addmission 2022</h1>
          <div className="grid  grid-cols-1 lg:grid-cols-3 gap-3 text-center">
            {addmissionData !== null && facultyData !== undefined ? (
              addmissionData?.map((item, i) => (
                <div
                  key={i}
                  className="font-semibold w-fit text-blue-500 shadow-lg border p-4 rounded-md"
                >
                  {item.title}
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        </div>
        {/* SECTION FOR THE FACULTY */}
        <div className="bg-white shadow-xl rounded-md p-10 space-y-3">
          <h1 className="font-semibold text-lg capitalize">
            Faculty At viva college new
          </h1>
          <div className="grid  grid-cols-1 lg:grid-cols-3 gap-3 ">
            {facultyData !== null && facultyData !== undefined ? (
              facultyData?.map((item, i) => (
                <div
                  key={i}
                  className="font-semibold whitespace-nowrap text-blue-500 shadow-lg border p-4 rounded-md w-fit "
                >
                  <p>{item.name}</p>
                  <p>{item.email_id}</p>
                  <p>{item.mobile_no}</p>
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        </div>
        {/* SECTION FOR THE SCHOLAR SHIP */}
        <div className="bg-white shadow-xl rounded-md p-10 space-y-3">
          <h1 className="font-semibold text-lg capitalize">
            Scholar Ship At viva college new
          </h1>
          <div className="grid  grid-cols-1  gap-3 ">
            {scholarshipData !== null ? (
              scholarshipData.map((item, i) => (
                <div key={i} className="border rounded-md ">
                  <span
                    onClick={() => {
                      scholarshipAccordian === i + 1
                        ? setscholarshipAccordian(0)
                        : setscholarshipAccordian(i + 1);
                    }}
                    className={`flex items-center justify-between text-lg cursor-pointer p-1 ${
                      scholarshipAccordian === i + 1
                        ? "border-2 border-blue-500 rounded-md"
                        : ""
                    }`}
                  >
                    {item.title}{" "}
                    <BsChevronDown
                      className={`${
                        scholarshipAccordian === i + 1
                          ? "rotate-180"
                          : "rotate-0"
                      } transition-all duration-300`}
                    />
                  </span>

                  <div
                    className={`${
                      scholarshipAccordian === i + 1
                        ? "h-fit border-t p-2 "
                        : "h-0 overflow-hidden "
                    } transition-all duration-300`}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        </div>

        {/* SECTION FOR THE PLACEMENT AND SALARY TREND */}
        <div className="bg-white shadow-xl rounded-md p-10 space-y-3">
          <h1 className="font-semibold text-lg capitalize">
            viva college new Placement and Salary Trends
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-3  gap-3 ">
            {placementData !== null ? (
              placementData.map((item, i) => (
                <div
                  key={i}
                  className="font-semibold  text-black shadow-lg border flex justify-center gap-2 w-fit whitespace-nowrap p-4 rounded-md"
                >
                  <GiTakeMyMoney className="text-4xl" />
                  <div className="font-normal">
                    <p className="text-gray-400">Average Package</p>
                    <p>{`INR ${item.average_package} L`}</p>
                  </div>
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        </div>

        {/* SECTION FOR THE IMAGE GALLERY OVERVIEW */}
        <Image data={college_gallery[0]} />

        {/* SECTION FOR THE  FACILITIES */}
        <Facilities campus_data={campus_data} />

        {/* SECTION FOR THE FAQ */}
        <div className="bg-white shadow-xl rounded-md p-10 space-y-3">
          <h1 className="font-semibold text-lg capitalize">
            FAQs About viva college new
          </h1>
          <div className="grid  grid-cols-1  gap-3 ">
            {faqData !== null ? (
              faqData?.map((item, i) => (
                <div key={i} className="border rounded-md ">
                  <span
                    onClick={() => {
                      faqAccordian === i + 1
                        ? setfaqAccordian(0)
                        : setfaqAccordian(i + 1);
                    }}
                    className={`flex items-center justify-between text-lg cursor-pointer p-1 ${
                      faqAccordian === i + 1
                        ? "border-2 border-blue-500 rounded-md"
                        : ""
                    }`}
                  >
                    {item.title}{" "}
                    <BsChevronDown
                      className={`${
                        faqAccordian === i + 1 ? "rotate-180" : "rotate-0"
                      } transition-all duration-300`}
                    />
                  </span>

                  <div
                    className={`${
                      faqAccordian === i + 1
                        ? "h-fit border-t p-2 "
                        : "h-0 overflow-hidden "
                    } transition-all duration-300`}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        </div>

        {/* SECTION FOR THE IMAGE RELATED NEWS */}
        <NewsSlider all_news={all_news} />

        {/* SECTION FOR THE ABOUT US */}
        <Aboutus
          data={college_data}
          height={"500"}
          width={"500"}
          fontSize={"text-sm bg-white shadow-xl rounded-md overflow-hidden"}
        />
      </div>
      <div className="p-5 hidden lg:block">
        <div className="bg-white shadow-xl rounded-md capitalize flex flex-col font-semibold space-y-4 px-8 pb-3">
          <h1 className="font-bold text-lg text-center">Top Colleges</h1>
          <TopCollege topclg={topclg} />

          {topclg === null && <NoData />}
        </div>
      </div>
    </div>
  );
};

export default Overview;
