import React from 'react'
import { useOutletContext } from 'react-router-dom'
import NoData from '../NoData';

const Placement = () => {
    const [_, placementData] = useOutletContext();
  
    return (
        <div className='grid grid-cols-1 lg:grid-cols-4'>
            <div className='col-span-4'>
                <h1 className='font-semibold text-xl mb-3'>Placement</h1>
                {placementData !== null ? (
                    placementData.map((item, i) => (
                        <table key={i} className='font-medium w-full text-left  '>
                            <tr className='border-2 border-gray-400 '>
                                <th className='p-1'>Average Package</th>
                                <td className='border-l-2 border-gray-400 p-1'>{item.average_package}</td>
                            </tr>
                            <tr className='border-2 border-gray-400  '>
                                <th className='p-1'>Companies visited</th>
                                <td className='border-l-2 border-gray-400 p-1'>{item.companies_visiting}</td>
                            </tr>
                            <tr className='border-2 border-gray-400  '>
                                <th className='p-1'>Highest International Package</th>
                                <td className='border-l-2 border-gray-400 p-1'>{item.highest_international_package}</td>
                            </tr>
                            <tr className='border-2 border-gray-400  '>
                                <th className='p-1'>Heghest Package</th>
                                <td className='border-l-2 border-gray-400 p-1'>{item.highest_package}</td>
                            </tr>
                            <tr className='border-2 border-gray-400  '>
                                <th className='p-1'>No. of Student Placed</th>
                                <td className='border-l-2 border-gray-400 p-1'>{item.no_of_students_placed}</td>
                            </tr>
                            <tr className='border-2 border-gray-400  '>
                                <th className='p-1'>Total Offer</th>
                                <td className='border-l-2 border-gray-400 p-1'>{item.total_offers}</td>
                            </tr>
                        </table>
                    ))

                ) : <NoData />}

            </div>
        </div>
    )
}

export default Placement