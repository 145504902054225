import React from 'react'
import { AiFillTwitterCircle } from 'react-icons/ai'
import { BiPhoneCall } from 'react-icons/bi'
import { BsFacebook, BsLinkedin, BsYoutube } from 'react-icons/bs'
import { FaInstagramSquare } from 'react-icons/fa'
import { GrMail } from 'react-icons/gr'

const ContactHeader = ({containerStyle,iconStyle}) => {
  return (
    <div className={`flex ${containerStyle}  w-full justify-between`}>
        <div className={`flex ${iconStyle}  gap-5`}>
          <span className="flex items-center gap-2 ">
            <BiPhoneCall className="text-emerald-500" />
            <p className="text-[0.8rem]">1800-572-9877</p>
          </span>
          <span className="flex items-center gap-2 ">
            <GrMail className="text-[#54ACAC]" />{" "}
            <p className="text-[0.8rem]">bookmycourse@gmail.com</p>
          </span>
        </div>

        <div className="flex  items-center  gap-2 py-1">
          <p className="text-gray-400 text-[0.8rem]">Connect With us:</p>
          <BsFacebook className="text-blue-600 w-6 h-6" />{" "}
          <BsLinkedin className="text-blue-800 rounded-full w-6 h-6" />{" "}
          <AiFillTwitterCircle className="w-7 h-7 text-blue-400" />{" "}
          <BsYoutube className="bg-red-500 text-white w-7 h-7 rounded-full p-1" />{" "}
          <FaInstagramSquare className="h-6 w-6  text-[#D13871]" />
        </div>
      </div>
  )
}

export default ContactHeader