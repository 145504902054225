import React from "react";
import MediaSlider from "../../utilites/MediaSlider";
import profileImg from "../../assets/images/Allicons.webp";

export default function Media() {
  return (
    <section className="py-12 text-center bg-[#fff]  h-full">
      <div className="max-w-6xl w-full mx-auto p-2 lg:p-0">
        <h2 className="lg:px-16 px-0 font-bold lg:text-3xl text-2xl mb-3 lg:mb-12 text-[#c00404] capitalize">bookmycourse in media</h2>
        <div className="mb-2">
          <MediaSlider />
        </div>
        <ul className="w-full table whitespace-nowrap flex-wrap">
          <li className="float-left w-full lg:w-1/2 align-middle px-0 lg:px-36 mt-10">
            <div className="text-left border-0 h-[76px] overflow-hidden bg-transparent transition-all table w-full py-2 px-3 rounded-sm">
              <div className="float-left w-[80px] h-[80px] ml-4 bg-white rounded-lg shadow-md">
                <div className="block mx-auto my-4 w-[50px] h-[50px] bg-no-repeat " style={{ backgroundImage: `url(${profileImg})`, backgroundPosition: "-18px -379px" }}></div>
              </div>
              <div className="ml-5">
                
                <div className="textGradinent overflow-hidden whitespace-normal ml-24 font-bold text-5xl ">
                25000+
                </div>
                <p className="text-[#333] ml-24 font-bold">
                Colleges in Database
                </p>
              </div>
            </div>
          </li>
          <li className="float-left w-full lg:w-1/2 align-middle px-0 lg:px-36 mt-10">
            <div className="text-left border-0 h-[76px] overflow-hidden bg-transparent transition-all table w-full py-2 px-3 rounded-sm">
              <div className="float-left w-[80px] h-[80px] ml-4 bg-white rounded-lg shadow-md">
                <div className="block mx-auto my-4 w-[50px] h-[50px] bg-no-repeat " style={{ backgroundImage: `url(${profileImg})`, backgroundPosition: "-18px -379px" }}></div>
              </div>
              <div className="ml-5">
                
                <div className="textGradinent overflow-hidden whitespace-normal ml-24 font-bold text-5xl ">
                25000+
                </div>
                <p className="text-[#333] ml-24 font-bold">
                Colleges in Database
                </p>
              </div>
            </div>
          </li>
          <li className="float-left w-full lg:w-1/2 align-middle px-0 lg:px-36 mt-10">
            <div className="text-left border-0 h-[76px] overflow-hidden bg-transparent transition-all table w-full py-2 px-3 rounded-sm">
              <div className="float-left w-[80px] h-[80px] ml-4 bg-white rounded-lg shadow-md">
                <div className="block mx-auto my-4 w-[50px] h-[50px] bg-no-repeat " style={{ backgroundImage: `url(${profileImg})`, backgroundPosition: "-18px -379px" }}></div>
              </div>
              <div className="ml-5">
                
                <div className="textGradinent overflow-hidden whitespace-normal ml-24 font-bold text-5xl ">
                25000+
                </div>
                <p className="text-[#333] ml-24 font-bold">
                Colleges in Database
                </p>
              </div>
            </div>
          </li>
          <li className="float-left w-full lg:w-1/2 align-middle px-0 lg:px-36 mt-10">
            <div className="text-left border-0 h-[76px] overflow-hidden bg-transparent transition-all table w-full py-2 px-3 rounded-sm">
              <div className="float-left w-[80px] h-[80px] ml-4 bg-white rounded-lg shadow-md">
                <div className="block mx-auto my-4 w-[50px] h-[50px] bg-no-repeat " style={{ backgroundImage: `url(${profileImg})`, backgroundPosition: "-18px -379px" }}></div>
              </div>
              <div className="ml-5">
                
                <div className="textGradinent overflow-hidden whitespace-normal ml-24 font-bold text-5xl ">
                25000+
                </div>
                <p className="text-[#333] ml-24 font-bold">
                Colleges in Database
                </p>
              </div>
            </div>
          </li>
         
        </ul>
      </div>
    </section>
  );
}
