import React from 'react'
import college from '../../../assets/images/college.avif'
const Image = ({data}) => {
    const { REACT_APP_IMGBASEURL } = process.env;
    return (
        <div className='bg-white shadow-xl rounded-md px-10 pt-10 pb-3 space-y-3'>
            <h1 className='font-semibold text-lg capitalize'>Image Gallery</h1>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-3  h-40 overflow-auto'>
                {
                    
                        <div  className='shadow-lg border p-1 rounded-md'>
                            <img className='object-cover' src={`${REACT_APP_IMGBASEURL}${data.image_name}`} alt='college img' />
                        </div>
                
                }
            </div>
            <button className='font-medium w-full hover:text-blue-500'>View All Images</button>

        </div>
    )
}

export default Image